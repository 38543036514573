// Function to format date as "23 August 2024"
const formatDate = (dateString) => {
    const date = new Date(dateString);
    return new Intl.DateTimeFormat('en-GB', { day: 'numeric', month: 'long', year: 'numeric' }).format(date);
  };
  
  // Function to format time as "6:30 PM"
  const formatTime = (timeString) => {
    const [hour, minute] = timeString.split(':');
    let hours = parseInt(hour);
    const period = hours >= 12 ? 'PM' : 'AM';
  
    // Convert to 12-hour format
    hours = hours % 12 || 12;
  
    return `${hours}:${minute} ${period}`;
  };


// module.exports = {
//     formatTime,
//     formatDate,
// };

export { formatTime, formatDate };
