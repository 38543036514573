import React, { useState, useEffect } from 'react';
import FormField from '../../components/shared/Forms/FormField';
import '../../Styles/EditProfile.css'
import { Link } from 'react-router-dom';
import { useSelector } from "react-redux";
import API from "../../services/API";
import {  toast } from 'react-toastify';
import { ToastConfig } from '../../components/shared/Forms/ToastConfig';
import{ClearLocalStorageAtMidnight} from '../../Utils/MidnightClear'

const EditProfile = () => {
  const { user } = useSelector((state) => state.auth);
  const [selectedFile, setSelectedFile] = useState(null);
  const [fileError, setFileError] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [formData, setFormData] = useState({
    profilePic: '',
    businessName: '',
    phoneNumber: '',
    location: '',
    email: '',
    username: ''
  });
  
  
  useEffect(() => {
    if (user) {

      // setSelectedFile(user.profilePicture);
      API.get(`/update/profile_picture/${user.BusinessId}`, { responseType: 'blob' })
      .then((response) => {
        const imageUrl = URL.createObjectURL(response.data);
        setSelectedFile(imageUrl); // Set the fetched image URL
      })
      .catch((error) => {
        console.error('Error fetching profile picture:', error);
      });

      setFormData({
        profilePic: user.profilePicture,
        businessName: user.BusinessName,
        phoneNumber: user.phone,
        location: user.location,
        email: user.email,
        username: user.UserName
      });
    }
  }, [user]);

  useEffect(() => {

    ClearLocalStorageAtMidnight();
  },[]);

  const handleFileChange = (e) => {
    if (e.target.files && e.target.files[0]) {
      const file = e.target.files[0];
      if (file.type.match('image.*')) {
        setSelectedFile(file);
        setFileError('');
      } else {
        setFileError('Please select a valid image file (png, jpeg, jpg)');
      }
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  // const handleSubmit = async (e) => {
  //   e.preventDefault();

  //   const updatedDetails = new FormData();
  //   updatedDetails.append('BusinessId', user?.BusinessId);
  //   updatedDetails.append('businessName', formData.businessName);
  //   updatedDetails.append('phoneNumber', formData.phoneNumber);
  //   updatedDetails.append('location', formData.location);
  //   updatedDetails.append('email', formData.email);

  //   if (selectedFile && typeof selectedFile !== 'string') {
  //     updatedDetails.append('profilePic', selectedFile);
  //   }

  //   try {
  //     const { data } = await API.put("/update/edit_profile", updatedDetails, {
  //       headers: {
  //         'Content-Type': 'multipart/form-data'
  //       }
  //     });

  //     if (data?.success) {
  //       toast.success("Profile updated successfully!", {
  //           position: "top-right",
  //           hideProgressBar: true,
  //           autoClose: 3000,
  //           className: 'custom-toast-success',
  //           bodyClassName: 'custom-toast-body',
  //           closeOnClick: true,
  //           pauseOnHover: true,
  //           draggable: true,
  //           progress: undefined,
  //       });
  //     } else {
  //       toast.error(data?.message || "Failed to update profile", ToastConfig);
  //     }
  //   } catch (error) {
  //     toast.error("An error occurred while updating profile.", ToastConfig);
  //   }
  // };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true); // Disable the button
  
    const updatedDetails = new FormData();
    updatedDetails.append('BusinessId', user?.BusinessId);
    updatedDetails.append('businessName', formData.businessName);
    updatedDetails.append('phoneNumber', formData.phoneNumber);
    updatedDetails.append('location', formData.location);
    updatedDetails.append('email', formData.email);
  
    if (selectedFile && typeof selectedFile !== 'string') {
      updatedDetails.append('profilePic', selectedFile);
    }
  
    try {
      const { data } = await API.put("/update/edit_profile", updatedDetails, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
  
      if (data?.success) {
        toast.success("Profile updated successfully!", {
          position: "top-right",
          hideProgressBar: true,
          autoClose: 3000,
          className: 'custom-toast-success',
          bodyClassName: 'custom-toast-body',
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } else {
        toast.error(data?.message || "Failed to update profile", ToastConfig);
      }
    } catch (error) {
      toast.error("An error occurred while updating profile.", ToastConfig);
    } finally {
      setIsSubmitting(false); // Re-enable the button once response is received
    }
  };
  

  return (
    <div className="EMS_Add_Event_Box box editProfileContainer">
      <h1 className="title">Edit Profile</h1>
      <form onSubmit={handleSubmit}>
      
        <div className="field is-flex is-justify-content-center">
          <div className="control">
            <label htmlFor="avatar-upload" className="profilepic-upload">
              {selectedFile && typeof selectedFile === 'string' ? (
                <img src={selectedFile} alt="ProfilePic" className="is-rounded" />
              ) : selectedFile ? (
                <img src={URL.createObjectURL(selectedFile)} alt="Avatar" className="is-rounded" />
              ) : (
                'Upload Image'
              )}
              <input
                type="file"
                id="avatar-upload"
                accept=".png, .jpeg, .jpg"
                onChange={handleFileChange}
                className="Signupbtn"
                name="selectedFile"
                style={{ display: "none" }}
              />
            </label>
            {fileError && <p className="help is-danger">{fileError}</p>}
          </div>
        </div>

        <div style={{ marginLeft: '2vh' }}>
          <label htmlFor="">Username</label>
          <input
            type="text"
            name="username"
            value={formData.username}
            readOnly={true}
            required={true}
            className="blurred-input input"
          />
        </div>

        <FormField
          label="Business Name"
          type="text"
          name="businessName"
          value={formData.businessName}
          onChange={handleChange}
          placeholder="Enter your business name"
          required={true}
        />
        <FormField
          label="Email"
          type="email"
          name="email"
          value={formData.email}
          onChange={handleChange}
          required={true}
        />
        <FormField
          label="Phone Number"
          type="text"
          name="phoneNumber"
          value={formData.phoneNumber}
          onChange={handleChange}
          placeholder="Enter your phone number"
          required={true}
        />
        <FormField
          label="Location"
          type="text"
          name="location"
          value={formData.location}
          onChange={handleChange}
          placeholder="Enter your location"
          required={true}
        />

        <div className="field">
          <div className="control">
             <button
              className="button btn-bg mx-3"
              type="submit"
              disabled={isSubmitting}
            >
              {isSubmitting ? "Saving..." : "Save"}
            </button>
          </div>
        </div>
      </form>
      <div className="field">
        <div className="control is-flex is-justify-content-center">
          <Link to="/change_password" className="mx-3 has-text-link">
            Change Password
          </Link>
        </div>
      </div>
    </div>
  );
};

export default EditProfile;