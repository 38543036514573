import React ,{useRef,useContext, useState,useEffect }from 'react'
import axios from 'axios';
import { toast } from 'react-toastify';
import '../../../Styles/OTP.css';
import 'bulma/css/bulma.css';
import API from "../../../services/API";
import { ToastConfig,SuccessToastConfig } from '../../../components/shared/Forms/ToastConfig';
import {  handleLoginViaEmail } from "../../../services/authService";

// import { useNavigate } from 'react-router-dom/dist/umd/react-router-dom.development';

const OTPModal = ({ isOpen2, onClose2,SendedOtp,_email_ }) => {
    const [otp, setOtp] = useState({ digit1: '', digit2: '', digit3: '', digit4: '' });
    // console.log('_email_:',_email_)
    const handleChange = (e) => {
        // console.log('SendedOtp:',SendedOtp);
      const { name, value } = e.target;
      setOtp({ ...otp, [name]: value });
    };

    const handleKeyDown = (e, field) => {
        if (e.key === "ArrowRight") {
          // Move to the next field if it's not the last one
          const nextField = document.querySelector(`input[name=digit${parseInt(field.charAt(field.length - 1)) + 1}]`);
          if (nextField) {
            nextField.focus();
          }
        } else if (e.key === "ArrowLeft") {
          // Move to the previous field if it's not the first one
          const prevField = document.querySelector(`input[name=digit${parseInt(field.charAt(field.length - 1)) - 1}]`);
          if (prevField) {
            prevField.focus();
          }
        }
      };


    
    const handleVerifyOTP = (e) => {
        // Concatenate OTP digits
        const fullOtp = Object.values(otp).join('');
        // console.log('Typed OTP:', fullOtp);
    
        // Check if both typed and sent OTPs match
        if (fullOtp === SendedOtp.toString()) {
            // If OTPs match, proceed to log in the user
            const email = _email_; 
            handleLoginViaEmail(e,email);
            onClose2();
        } else {
            // If OTPs do not match, inform the user
            // console.error("OTP verification failed. Please try again.");
            toast.error('Your OTP is incorrect. Please try again.', ToastConfig);
        }
    };
     
  // const navigate=useNavigate();
    
  
    return (
    <>
      <div className={`modal ${isOpen2 ? 'is-active' : ''}`}>
        <div className="modal-background" ></div>
        <div className="modal-card">
          <header className="modal-card-head">
            <p className="modal-card-title">Enter OTP</p>
            <button className="delete" aria-label="close" onClick={onClose2}></button>
          </header>
          <section className="modal-card-body">
            <p style={{ textAlign: 'center' }}>We have sent you an OTP on your Email.</p>

            <div style={{ display: 'flex', justifyContent: 'center', gap: '10px' }}>
              {Object.keys(otp).map((key, index) => (
                <input
                  key={index}
                  type="text"
                  name={key}
                  value={otp[key]}
                  onChange={handleChange}
                  onKeyDown={(e) => handleKeyDown(e, key)}
                  maxLength="1"
                  onInput={(e) => e.target.value = e.target.value.replace(/[^0-9]/g, '').slice(0, 1)}
                  className='input EMS_Add_Event_input'
                  style={{ width: '3rem', textAlign: 'center' }}
                />
              ))}
            </div>
          </section>
          <footer className="modal-card-foot is-justify-content-center has-background-white">
            <button className="button btn-bg" onClick={handleVerifyOTP}>
            {/* <button className="button btn-bg" > */}
              Verify Account
            </button>
          </footer>
        </div>
      </div>

      
    </>
    );
}

export default OTPModal