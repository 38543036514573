import React, { useState, useEffect } from 'react';
import 'bulma/css/bulma.css';
import '../../../Styles/OwnerDetailForm.css';
import FormField from './FormField'; 
import InputMask from 'react-input-mask';

const OwnerDetailForm = ({ onNext,onEdit, onSave, onClear, formData: initialFormData, mode }) => {
  const [formData, setFormData] = useState({
    ownerName: '',
    cnic: '',
    phone: '',
    email: '',
    Description: ''
  });

  useEffect(() => {
    if (initialFormData) {
      setFormData(initialFormData);
    }
  }, [initialFormData]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
    if (onEdit) onEdit();
  };

  const handleClear = () => {
    setFormData({
      ownerName: '',
      cnic: '',
      phone: '',
      email: '',
      Description: ''
    });
    onClear();
  };

 
    const [isSubmitting, setIsSubmitting] = useState(false);
  
    const handleSubmit = async (e) => {
      e.preventDefault();
      const { ownerName, cnic, phone } = formData;
  
      if (ownerName && cnic && phone) {
        setIsSubmitting(true); // Disable the button while submitting
        try {
          if (mode === 'add') {
            onNext(formData);
          } else {
            await onSave(formData); // Ensure it waits for save
          }
        } catch (error) {
          console.log('Error:', error);
        } finally {
          setIsSubmitting(false); // Re-enable button after the response
        }
      } else {
        console.log('Please fill all required fields');
      }
    };

  return (
    <div className="container" style={{marginTop:"8%"}}>
      <div className="EMS_Add_Event_Box box">
        <h1 className="title">Event Owner Details</h1>
        <form onSubmit={handleSubmit}>
          <div className="columns">
            <FormField
              label="Customer Name"
              type="text"
              name="ownerName"
              value={formData.ownerName}
              onChange={handleChange}
              required={true}
            />
            <div className="field column">
              <label className="EMS_Add_Event_Label">Customer CNIC <span className="has-text-danger">*</span></label>
              <div className="control">
                <InputMask
                  mask="99999-9999999-9"
                  value={formData.cnic}
                  onChange={handleChange}
                  className="input InputMask"
                  name="cnic"
                  placeholder="35123-4567890-1"
                  required
                />
              </div>
            </div>
          </div>
          <div className="columns">
            <FormField
              label="Customer Phone"
              type="text"
              name="phone"
              value={formData.phone}
              onChange={handleChange}
              required={true}
            />
            <FormField
              label="Customer Email"
              type="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
            />
          </div>
          <div className="field">
            <FormField
              label="Any other thing"
              type="text" 
              name="Description"
              value={formData.Description}
              onChange={handleChange}
              placeholder="Any other thing you can add here"
              isTextarea={true}
            />
          </div>
          <div className="field is-grouped">
            <div className="control">
              <button type="button" className="button is-light" onClick={handleClear}>Clear Fields</button>
            </div>
            <div className="control">
              {/* <button type="submit" className="button btn-bg">{mode === 'add' ? 'Next' : 'Save'}</button> */}
              <button
                type="submit"
                className="button btn-bg"
                disabled={isSubmitting}
              >
                {isSubmitting ? 'Saving...' : (mode === 'add' ? 'Next' : 'Save')}
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default OwnerDetailForm;
