import React, { useState,useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { FaEdit, FaTrash, FaFilePdf, FaFileExcel ,FaFilter, FaArrowLeft, FaArrowRight } from 'react-icons/fa';
import '../../Styles/EventsList.css';
import '../../Styles/EditModal.css';
import EditModal from '../../components/shared/Modal/EditModal';
import OwnerDetailForm from '../../components/shared/Forms/OwnerDetailForm';
import EventDetailForm from '../../components/shared/Forms/EventDetailForm';
import CateringDetailForm from '../../components/shared/Forms/CateringDetailForm';
import FoodDetailForm from '../../components/shared/Forms/FoodDetailForm';
import ConfirmModal from '../../components/shared/Modal/Modal';
import PaymentsDetail from '../../components/shared/Forms/PaymentsDetail';
import { format } from 'date-fns';
import { enUS } from 'date-fns/locale';
import { useSelector } from "react-redux";
import API from "../../services/API";
import { jsPDF } from 'jspdf';
import 'jspdf-autotable'; // Import the autotable plugin
import { utils, writeFile } from 'xlsx';
import * as XLSX from 'xlsx-js-style';
import {  toast } from 'react-toastify';
import { ToastConfig,SuccessToastConfig,InfoToastConfig } from '../../components/shared/Forms/ToastConfig';
import logo from '../../Assets/logo.png'
import { Spinner } from 'react-bootstrap'; 
import{ClearLocalStorageAtMidnight} from '../../Utils/MidnightClear'
import { formatTime, formatDate } from '../../Utils/DateTimeFormat';
import { fetchAndSetEvents } from '../../Utils/FetchEvents';
import Loader from '../../components/shared/Layout/Loader';

const AllEvents = () => {
  const navigate = useNavigate();

  const { user } = useSelector((state) => state.auth);
  // console.log('user:',user);
  // State for modal visibility
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isPDFLoading, setIsPDFLoading] = useState(false);
  const [isExcelLoading, setIsExcelLoading] = useState(false);
  const [loading, setLoading] = useState(true);

  // State for currently selected event ID
  const [currentEventId, setCurrentEventId] = useState(null);

  const [events, setEvents] = useState([]);
  // const [filter, setFilter] = useState(user?.userFilter);
  const [filter, setFilter] = useState(user?.userFilter || 'All');

  const handleFilterChange = async (event) => {
    const selectedFilter = event.target.value;
    setFilter(selectedFilter);
    try {
      const response = await API.put(`/auth/edit-FilterPreference/${user.BusinessId}`, {
        filter: selectedFilter,
      });
    } catch (error) {
      console.error("Error updating filter:", error);
    }
  };
 
  const generateEventRecord = (index) => {
    return {
      // _id: `66c645ac8e67a6c7ff5251e${index.toString().padStart(2, '0')}`,
      ownerDetails: {
        ownerName: `Owner ${index}`,
        cnic: `35202-9186344-${index}`,
        phone: `034777040${index.toString().padStart(4, '0')}`,
        email: `owner${index}@gmail.com`,
        Description: `Description for event ${index}`
      },
      eventDetails: {
        numOfPersons: 250 + index,
        ratePerGuest: 321 + index,
        eventType: "Walima",
        customEventType: "",
        eventDay: "2024-08-23T00:00:00.000Z",
        eventTime: "18:30",
        hallNo: "",
        Description: `Event Description ${index}`,
        eventStatus: "Done"
      },
      cateringDetails: {
        numOfChairs: 354 + index,
        numOfSofas: null,
        decorationTheme: "",
        decorItems: {
          mainStageFlower: {
            checked: true,
            amount: 12111111 + index
          }
        }
      },
      foodDetails: {
        numDishes: "1",
        customNumDishes: "",
        dishes: [
          {
            name: `Dish ${index}`,
            DishType: "main course",
            // _id: `66c645ac8e67a6c7ff5251e${(index + 1).toString().padStart(2, '0')}`
          }
        ],
        Description: ""
      },
      paymentsDetails: {
        first: {
          amount: 40000 + index,
          dateReceived: "2024-08-22T00:00:00.000Z",
          receivedBy: `Person ${index}`
        }
      },
      totalCost: 12191361 + index,
      createdAt: new Date().toISOString(),
      updatedAt: new Date().toISOString(),
      __v: 0
    };
  };
  
  // Function to generate and cache 1000 event record
  const DB100Events = async () => {
    const events = Array.from({ length: 100 }, (_, index) => generateEventRecord(index));

    try {
        // Send the generated events to the server for bulk insertion
        const { data } = await API.post(`/eventForms/addBulkEvents/${user?.UserName}`, { events });

        if (data?.success) {
            console.log('100 events have been added to the database successfully.');
        } else {
            console.error('Failed to add events to the database:', data?.message);
        }
    } catch (error) {
        console.error('Error while adding events:', error);
    }
};


  useEffect(() => {
    ClearLocalStorageAtMidnight();
  },[]);

  useEffect(() => {
    // DB100Events();
    fetchAndSetEvents(user?.UserName, setEvents, setLoading);

  }, [user, filter]);

  const filteredEvents = filter === 'All' ? events : events.filter(event => event.eventDetails.eventStatus === filter);
  
  // State for owner details
  const [ownerDetails, setOwnerDetails] = useState({
    ownerName: '',
    cnic: '',
    phone: '',
    email: '',
    Description: ''
  });

  // State for event details
  const [eventDetails, setEventDetails] = useState({
    numOfPersons: '',
    eventType: '',
    eventDay: '',
    eventTime: '',
    Description: ''
  });

  const [cateringDetails, setCateringDetails] = useState({
    numOfChairs: '',
    numOfSofas: '',
    decorationTheme: '',
    decorItems: {
      mainStageFlower: { checked: false, amount: '' },
      dj: { checked: false, amount: '' },
      followSpotlight: { checked: false, amount: '' },
      coolFireAnar: { checked: false, amount: '' },
      trussUChokta: { checked: false, amount: '' },
      danceFloor: { checked: false, amount: '' },
      seeThroughFloors: { checked: false, amount: '' },
      glassFloorWalkway: { checked: false, amount: '' },
      panelPartition: { checked: false, amount: '' },
      heater: { checked: false, amount: '' },
    },
  });


  const [paymentDetails, setPaymentDetails] = useState({
    first: { amount: '', dateReceived: format(new Date(), 'yyyy-MM-dd', { locale: enUS }), receivedBy: '' },
    second: { amount: '', dateReceived: '', receivedBy: '' },
    third: { amount: '', dateReceived: '', receivedBy: '' },
    fourth: { amount: '', dateReceived: '', receivedBy: '' },
    fifth: { amount: '', dateReceived: '', receivedBy: '' },
  });
  
  const [totalCharges, setTotalCharges] = useState(0);
  
  const [foodDetails, setFoodDetails] = useState({
    numDishes: '',
    customNumDishes: '',
    dishes: [],
    drinks: {
      pepsiCoke: { checked: false, quantity: '' },
      sprite7up: { checked: false, quantity: '' },
      fanta: { checked: false, quantity: '' },
    },
    Tea: {
      tea: { checked: false, quantity: '' },
      greenTea: { checked: false, quantity: '' }
    },
    Description: ''
  });

  // State for delete confirmation modal
  const [isConfirmModalActive, setIsConfirmModalActive] = useState(false);
  const [eventToDelete, setEventToDelete] = useState(null);
  
  
  const handleRowClick = (event) => {
    navigate(`/event-detail/${event._id}`, { state: { event } });
  };

  const handleEditClick = (event, e) => {
    e.stopPropagation();
    setOwnerDetails(event.ownerDetails);
    setEventDetails(event.eventDetails);
    setCateringDetails(event.cateringDetails); 
    setFoodDetails(event.foodDetails); 
    setPaymentDetails(event.paymentsDetails);
    setTotalCharges(event.totalCost);
    setCurrentEventId(event._id);
    setIsModalOpen(true);
  };

  const handleDeleteClick = (event, e) => {
    e.stopPropagation();
    setEventToDelete(event);
    setIsConfirmModalActive(true);
  };

const handleConfirmDelete = async () => {
  if (!eventToDelete) return;

  try {
    const { data } = await API.delete(`/eventForms/delete_eventDetails/${eventToDelete._id}/${user?.UserName}`);
    
    if (data?.success) {
      toast.success(data.message, SuccessToastConfig);

      // Remove the deleted event from the cache
      const cacheName = 'my-event-cache'; // Cache name used in your application
      const cache = await caches.open(cacheName);
      const cacheKey = `events-${user?.UserName}-${filter}`; // Cache key used for storing events

      const cachedResponse = await cache.match(cacheKey);
      if (cachedResponse) {
        const cachedData = await cachedResponse.json();
        const updatedEvents = cachedData.filter(event => event._id !== eventToDelete._id); // Remove the deleted event

        // Update the cache with the filtered events
        const response = new Response(JSON.stringify(updatedEvents));
        await cache.put(cacheKey, response);
        setEvents(updatedEvents); 
      }
    } else {
      toast.error("Event not deleted. Try again later", ToastConfig);
    }
  } catch (error) {
    console.error('Error deleting event:', error.response?.data?.message || error.message);
  } finally {
    setIsConfirmModalActive(false);
    setEventToDelete(null);
  }
};
  

  const handleCancelDelete = () => {
    setIsConfirmModalActive(false);
    setEventToDelete(null);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setOwnerDetails({
      ownerName: '',
      cnic: '',
      phone: '',
      email: '',
      Description: ''
    });
    setEventDetails({
      numOfPersons: '',
      eventType: '',
      eventDay: '',
      eventTime: '',
      Description: ''
    });
    setCateringDetails({
      numOfChairs: '',
      numOfSofas: '',
      decorationTheme: '',
      decorItems: {
        mainStageFlower: { checked: false, amount: '' },
        dj: { checked: false, amount: '' },
        followSpotlight: { checked: false, amount: '' },
        coolFireAnar: { checked: false, amount: '' },
        trussUChokta: { checked: false, amount: '' },
        danceFloor: { checked: false, amount: '' },
        seeThroughFloors: { checked: false, amount: '' },
        glassFloorWalkway: { checked: false, amount: '' },
        panelPartition: { checked: false, amount: '' },
        heater: { checked: false, amount: '' },
      }
    });
    setFoodDetails({
      numDishes: '',
      customNumDishes: '',
      dishes: [],
      drinks: {
        pepsiCoke: { checked: false, quantity: '' },
        sprite7up: { checked: false, quantity: '' },
        fanta: { checked: false, quantity: '' },
      },
      Tea: {
        tea: { checked: false, quantity: '' },
        greenTea: { checked: false, quantity: '' }
      },
      Description: ''
    });
    setCurrentEventId(null);
  };
  

  const handleOwnerFormClear = () => {
    setOwnerDetails({
      ownerName: '',
      cnic: '',
      phone: '',
      email: '',
      Description: ''
    });
  };

  const handleEventFormClear = () => {
    setEventDetails({
      numOfPersons: '',
      eventType: '',
      eventDay: '',
      eventTime: '',
      Description: ''
    });
  };

  const handleCateringFormClear = () => {
    setCateringDetails({
      numOfChairs: '',
      numOfSofas: '',
      decorationTheme: '',
      decorItems: {
        mainStageFlower: { checked: false, amount: '' },
        dj: { checked: false, amount: '' },
        followSpotlight: { checked: false, amount: '' },
        coolFireAnar: { checked: false, amount: '' },
        trussUChokta: { checked: false, amount: '' },
        danceFloor: { checked: false, amount: '' },
        seeThroughFloors: { checked: false, amount: '' },
        glassFloorWalkway: { checked: false, amount: '' },
        panelPartition: { checked: false, amount: '' },
        heater: { checked: false, amount: '' },
      }
    });
  };
  
  const handleFoodFormClear = () => {
    setFoodDetails({
      numDishes: '',
      customNumDishes: '',
      dishes: [],
      drinks: {
        pepsiCoke: { checked: false, quantity: '' },
        sprite7up: { checked: false, quantity: '' },
        fanta: { checked: false, quantity: '' },
      },
      Tea: {
        tea: { checked: false, quantity: '' },
        greenTea: { checked: false, quantity: '' }
      },
      Description: ''
    });
  };
  
  // Handler for clearing payment form
  const handlePaymentsFormClear = () => {
    setPaymentDetails({
      first: { amount: '', dateReceived: format(new Date(), 'yyyy-MM-dd', { locale: enUS }), receivedBy: '' },
      second: { amount: '', dateReceived: '', receivedBy: '' },
      third: { amount: '', dateReceived: '', receivedBy: '' },
      fourth: { amount: '', dateReceived: '', receivedBy: '' },
      fifth: { amount: '', dateReceived: '', receivedBy: '' },
    });
  };
  

  const updateCache = async (userName, filter, currentEventId, updatedFields) => {
    const cacheName = 'my-event-cache';
    const cacheKey = `events-${userName}-${filter}`;
    const cache = await caches.open(cacheName);
    const cachedResponse = await cache.match(cacheKey);
  
    if (cachedResponse) {
      const cachedData = await cachedResponse.json();
  
      // Find the event in the cached data and update it
      const updatedEvents = cachedData.map((event) => {
        if (event._id === currentEventId) {
          return {
            ...event,
            ...updatedFields, // Merge the updated fields
          };
        }
        return event;
      });
  
      // Update the cache with the new list of events
      const response = new Response(JSON.stringify(updatedEvents));
      await cache.put(cacheKey, response);
  
      // Return the updated events for immediate state update
      return updatedEvents;
    }
  
    return null; // In case there's no cached data
  };
  
  const handleOwnerFormSave = async (formData) => {
    setOwnerDetails(formData);
  //  console.log("formData:",formData);
    try {
      const { data } = await API.put(`/editEvent/edit_ownerDetails/${currentEventId}/${user?.UserName}`, formData);
  
      if (data?.success) {
        if (data.message === "You have not changed anything.") {
          toast.info(data.message, InfoToastConfig);
        } else if (data.message === "Owner details updated successfully") {
          toast.success(data.message, SuccessToastConfig);
  
          // Update the cached data after successful update
          const updatedEvents = await updateCache(user?.UserName, filter, currentEventId, {
            ownerDetails: formData,
          });
  
          // Update the state with the new list of events
          if (updatedEvents) {
            setEvents(updatedEvents);
          }
        }
      } else {
        toast.error("Event Details not Saved. Try again later", ToastConfig);
      }
    } catch (error) {
      console.log('Error in API call:', error);
    }
  };
  
  const handleEventFormSave = async (formData, cost) => {
    setEventDetails(formData);
  
    try {
      const { data } = await API.put(`/editEvent/edit_eventDetails/${currentEventId}/${user?.UserName}`, formData);
  
      if (data?.success) {
        if (data.message === "You have not changed anything.") {
          toast.info(data.message, InfoToastConfig);
        } else if (data.message === "Event details updated successfully") {
          setTotalCharges(data?.event.totalCost);
          toast.success(data.message, SuccessToastConfig);
  
          // Update the cached data
          const updatedEvents = await updateCache(user?.UserName, filter, currentEventId, {
            eventDetails: formData,
            cateringDetails: data?.event.cateringDetails,
            totalCost: data?.event.totalCost,
          });
  
          // Update the state with the new list of events
          if (updatedEvents) {
            setEvents(updatedEvents);
          }
        }
      } else {
        toast.error("Event Details not Saved. Try again later", ToastConfig);
      }
    } catch (error) {
      console.log('Error in API call:', error);
    }
  };
  

  const handleCateringFormSave = async (formData) => {
    setCateringDetails(formData);
  
    try {
      const { data } = await API.put(`/editEvent/edit_cateringDetails/${currentEventId}/${user?.UserName}`, formData);
  
      if (data?.success) {
        if (data.message === "You have not changed anything.") {
          toast.info(data.message, InfoToastConfig);
        } else if (data.message === "Catering details updated successfully") {
          setTotalCharges(data?.event.totalCost);
          toast.success(data.message, SuccessToastConfig);
  
          // Update the cached data
          const updatedEvents = await updateCache(user?.UserName, filter, currentEventId, {
            cateringDetails: formData,
            totalCost: data?.event.totalCost,
          });
  
          // Update the state with the new list of events
          if (updatedEvents) {
            setEvents(updatedEvents);
          }
        }
      } else {
        toast.error("Catering Details not Saved. Try again later", ToastConfig);
      }
    } catch (error) {
      console.log('Error in API call:', error);
    }
  };

  const handleFoodFormSave = async (formData) => {
    setFoodDetails(formData);
  
    try {
      const { data } = await API.put(`/editEvent/edit_foodDetails/${currentEventId}/${user?.UserName}`, formData);
  
      if (data?.success) {
        if (data.message === "You have not changed anything.") {
          toast.info(data.message, InfoToastConfig);
        } else if (data.message === "Food details updated successfully") {
          toast.success(data.message, SuccessToastConfig);
  
          // Update the cached data
          const updatedEvents = await updateCache(user?.UserName, filter, currentEventId, {
            foodDetails: formData,
          });
  
          // Update the state with the new list of events
          if (updatedEvents) {
            setEvents(updatedEvents);
          }
        }
      } else {
        toast.error("Food Details not Saved. Try again later", ToastConfig);
      }
    } catch (error) {
      console.log('Error in API call:', error);
    }
  };
  
  const handlePaymentsFormSave = async(formData) => {
    setPaymentDetails(formData);
    try {
      const { data } = await API.put(`/editEvent/edit_paymentDetails/${currentEventId}/${user?.UserName}`, formData);
      if (data?.success) {
        // console.log('data.message:',data.message)
        if (data.message === "You have not changed anything.") {
          toast.info(data.message, InfoToastConfig);
        } 
        else if(data.message === "Payment details updated successfully"){
          toast.success(data.message, SuccessToastConfig);

          const updatedEvents = await updateCache(user?.UserName, filter, currentEventId, {
            paymentsDetails: formData,
          });
  
          if (updatedEvents) {
            setEvents(updatedEvents);
          }

        }
      } else {
        toast.error("Payment Details not Saved. Try again later", ToastConfig);
      }
    } catch (error) {
      console.log('Error in API call:', error);
    }
  }; 


  const handleExportExcel = () => {
    setIsExcelLoading(true);

    setTimeout(() => {
    // Create a new workbook
    const wb = XLSX.utils.book_new();

    // Define headers for the Excel file
    const headers = [
      'Owner Name',
      'CNIC',
      'Phone',
      'Email',
      'Description',
      'Event Type',
      'Number of Persons',
      'Rate per Guest',
      'Event Day',
      'Event Time',
      'Hall No',
      'Event Description',
      'Number of Chairs',
      'Number of Sofas',
      'Decoration Theme',
      'Decor Items',
      'Number of Dishes',
      'Dishes',
      'Food Description',
      'Total Charges',
      'Payments',
      'Event Status' // Added the Event Status column for styling
    ];

    // Filter events based on selected filter (Active or Done)
    const filteredEvents = filter === 'All' 
      ? events 
      : events.filter(event => event.eventDetails.eventStatus === filter);

    // Prepare the data for filtered events
    const data = filteredEvents.map((event) => {
      const ownerDetails = event.ownerDetails || {};
      const eventDetails = event.eventDetails || {};
      const cateringDetails = event.cateringDetails || {};
      const foodDetails = event.foodDetails || {};
      const paymentsDetails = event.paymentsDetails || {};

      // Format payments for the Excel file
      const formatPayments = () => {
        return Object.entries(paymentsDetails).map(([key, payment]) => {
          if (payment.amount) {
            return `${key.replace(/([A-Z])/g, ' $1').replace(/^./, str => str.toUpperCase())}: Amount: ${payment.amount}, Receiving Date: ${formatDate(payment.dateReceived)}, Received By: ${payment.receivedBy}`;
          }
          return null;
        }).filter(Boolean).join('\n');
      };

      // Return an array of data for a single event
      return [
        ownerDetails.ownerName || '',
        ownerDetails.cnic || '',
        ownerDetails.phone || '',
        ownerDetails.email || '',
        ownerDetails.Description || '',
        eventDetails.eventType === 'Custom' ? eventDetails.customEventType : eventDetails.eventType || '',
        eventDetails.numOfPersons || '',
        eventDetails.ratePerGuest || '',
        formatDate(eventDetails.eventDay) || '',
        formatTime(eventDetails.eventTime) || '',
        eventDetails.hallNo || '',
        eventDetails.Description || '',
        cateringDetails.numOfChairs || '',
        cateringDetails.numOfSofas || '',
        cateringDetails.decorationTheme || '',
        Object.entries(cateringDetails.decorItems || {}).map(([key, value]) => value.checked ? `${key.replace(/([A-Z])/g, ' $1').replace(/^./, str => str.toUpperCase())}: ${value.amount} PKR` : '').filter(Boolean).join('\n') || '',
        foodDetails.numDishes === "custom" ? foodDetails.customNumDishes || '' : foodDetails.numDishes || 'None',
        foodDetails.dishes ? foodDetails.dishes.map((dish, index) => `Dish ${index + 1}: ${dish.name} (${dish.DishType})`).join('\n') : 'No dishes available',
        foodDetails.Description || '',
        event.totalCost.toLocaleString() + ' PKR',
        formatPayments() || 'No payments available',
        eventDetails.eventStatus || '' // Add event status at the end
      ];
    });

    // Combine the headers and data
    const sheetData = [headers, ...data];

    // Create a worksheet with the combined data
    const ws = XLSX.utils.aoa_to_sheet(sheetData);

    // Apply styles to the header row
    headers.forEach((_, index) => {
      const cellRef = XLSX.utils.encode_cell({ r: 0, c: index });
      if (!ws[cellRef]) return;
      ws[cellRef].s = {
        font: { bold: true },   // Bold text
        fill: {                // Background color
          fgColor: { rgb: "D9EAD3" } // Light green background
        },
        alignment: {
          vertical: 'center',
          horizontal: 'center'
        }
      };
    });

    // Apply background color based on event status in the last column
    filteredEvents.forEach((event, index) => {
      const statusCellRef = XLSX.utils.encode_cell({ r: index + 1, c: headers.length - 1 }); // Last column for event status
      const eventStatus = event.eventDetails.eventStatus;
      ws[statusCellRef].s = {
        fill: {
          fgColor: {
            rgb: eventStatus === 'Active' ? "#FFDE4D" : "#0A6847" // Yellow for Active, Green for Done
          }
        }
      };
    });

    // Add worksheet to workbook
    XLSX.utils.book_append_sheet(wb, ws, 'Event Details');

    // Write the workbook to a file
    XLSX.writeFile(wb, `event_details_${filter}.xlsx`);
    setIsExcelLoading(false);
  }, 2000);

  };
  
  const handleExportPDF = () => {
    setIsPDFLoading(true); // Start loading, show the spinner

    // Use setTimeout with 0 delay to force the UI to update first
    setTimeout(() => {
      // Generate PDF asynchronously
      const doc = new jsPDF();
      const pageWidth = doc.internal.pageSize.getWidth();
      const logoWidth = 50;
      const logoHeight = 40;
      const logoX = (pageWidth - logoWidth) / 2;
      const logoY = 10;
      const marginAfterLogo = 7;

      // Add the logo image
      doc.addImage(logo, 'PNG', logoX, logoY, logoWidth, logoHeight, undefined, 'FAST');

      // Position the heading below the logo with margin
      const headingY = logoY + logoHeight + marginAfterLogo;
      doc.setFontSize(20);
      doc.setFont('Helvetica', 'bold');
      doc.text('Events List', pageWidth / 2, headingY, { align: 'center' });

      // Filter events
      const filteredEvents = filter === 'All' ? events : events.filter(event => event.eventDetails.eventStatus === filter);

      // Define columns and rows for the table
      const columns = ["No.", "Event Owner", "Event Type", "Event Status", "Event Day", "Event Time"];
      const rows = filteredEvents.map((event, index) => [
        index + 1,
        event.ownerDetails.ownerName,
        event.eventDetails.eventType === 'Custom' ? event.eventDetails.customEventType : event.eventDetails.eventType,
        formatStatusWithBadge(event.eventDetails.eventStatus),
        formatDate(event.eventDetails.eventDay),
        formatTime(event.eventDetails.eventTime)
      ]);

      const tableOptions = {
        startY: 60,
        headStyles: { fillColor: '#222831' },
        theme: 'striped',
        margin: { top: 60, bottom: 30 },
        columnStyles: { 0: { cellWidth: 20 } }
      };

      // Generate the table and save the PDF
      doc.autoTable(columns, rows, tableOptions);
      doc.save('events-list.pdf');

      setIsPDFLoading(false); // Stop loading after saving the PDF
    }, 2000);
  };

  const formatStatusWithBadge = (status) => {
    const badgeStyles = {
      Active: {
        text: 'Active',
        color: 'black',
        backgroundColor: '#FFDE4D', 
      },
      Done: {
        text: 'Done',
        color: '#FFFFFF',
        backgroundColor: '#0A6847',
      }
    };
  
    const { text, color, backgroundColor } = badgeStyles[status] || {};
    return {
      content: text,
      styles: { fillColor: backgroundColor, textColor: color, fontStyle: 'bold', halign: 'center' }
    };
  };
  
    const [currentPage, setCurrentPage] = useState(1);
    const recordsPerPage = 10;

    // Calculate the index of the first and last records to display
    const indexOfLastRecord = currentPage * recordsPerPage;
    const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
    const currentRecords = filteredEvents.slice(indexOfFirstRecord, indexOfLastRecord);

    // Total number of pages
    let totalPages = Math.ceil(filteredEvents.length / recordsPerPage)|| 1;
    // if(totalPages==0) totalPages=1;

    const handlePageChange = (pageNumber) => {
      // Ensure page number is within valid range
      if (pageNumber < 1) {
        setCurrentPage(1); // Set to first page if pageNumber is less than 1
      } else if (pageNumber > totalPages) {
        setCurrentPage(totalPages); // Set to last page if pageNumber exceeds totalPages
      } else {
        setCurrentPage(pageNumber); // Otherwise, set to specified pageNumber
      }
    };

  return (
    <div className="table-header" style={{width:'100%'}}>
      <h1 className="title" style={{fontSize:'5vh'}}>Events List</h1>
      {loading ? (
      <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <Loader />
        <div>
          <p style={{ color: '#222831', fontSize: 'normal', fontWeight:'bold'}}>Please Wait...</p>
        </div>
      </div>
      ) : (
      <>
        <div className="table-actions">
          <div>
          <button className="button is-small export-btn has-background-white " onClick={handleExportPDF} disabled={isPDFLoading}>
            {isPDFLoading ? (
              <span className="spinner-pdf"></span> // Custom spinner
            ) : (
              <FaFilePdf className="pdfSVG" style={{ marginRight: '1vh', fontSize: '3vh', color: '#b11c20' }} />
            )}
            {isPDFLoading ? '' : 'Export PDF'}
          </button>
          <button className="button is-small export-btn has-background-white" onClick={handleExportExcel} disabled={isExcelLoading}>
            {isExcelLoading ? (
              <span className="spinner-excel"></span> // Custom spinner
            ) : (
              <FaFileExcel className="pdfSVG" style={{ marginRight: '1vh', color: "#0f753e", fontSize: '3vh' }} />
            )}
            {isExcelLoading ? '' : 'Export Excel'}
          </button>
          </div>
          <div className="is-flex is-align-items-center select">
            <FaFilter style={{ marginRight: '0.5rem' }} />
            <span>Filter:</span>
            <select value={filter} onChange={handleFilterChange} className='has-background-white has-text-black ml-2'>
              <option value="All">All</option>
              <option value="Active">Active</option>
              <option value="Done">Done</option>
            </select>
          </div>

        </div>
          <div className="table-container">
            <table className="events-table">
              <thead>
                <tr>
                  <th>Event No.</th>
                  <th>Event Owner Name</th>
                  <th>Event Type</th>
                  <th>Event Status</th>
                  <th>Event Day</th>
                  <th>Event Time</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {currentRecords.length > 0 ? (
                  currentRecords.map((event, index) => (
                    <tr key={event._id} onClick={() => handleRowClick(event)}>
                      <td>{index + 1 + indexOfFirstRecord}</td>
                      <td>{event.ownerDetails.ownerName}</td>
                      <td>
                        {event.eventDetails.eventType === 'Custom'
                          ? event.eventDetails.customEventType
                          : event.eventDetails.eventType}
                      </td>
                      <td>
                        <span className={`badge ${event.eventDetails.eventStatus.toLowerCase()}`}>
                          {event.eventDetails.eventStatus}
                        </span>
                      </td>
                      <td>{formatDate(event.eventDetails.eventDay)}</td>
                      <td>{formatTime(event.eventDetails.eventTime)}</td>
                      <td>
                        <button className="edit-btn" onClick={(e) => handleEditClick(event, e)}>
                          <FaEdit />
                        </button>
                        <button className="delete-btn" onClick={(e) => handleDeleteClick(event, e)}>
                          <FaTrash />
                        </button>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="7">
                      <div className="has-text-centered is-flex is-justify-content-center is-align-items-center p-5">
                        <h3 className="has-text-weight-bold">No event added yet</h3>
                      </div>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>

          {/* Pagination Controls */}
          <div className="pagination-controls">
            <button
              onClick={() => handlePageChange(1)}
              disabled={currentPage === 1}
            >
                First
            </button>
            <button
              onClick={() => handlePageChange(currentPage - 1)}
              disabled={currentPage === 1}
            >
              <FaArrowLeft />
            </button>
            <span>Page {currentPage} of {totalPages}</span>
            <button
              onClick={() => handlePageChange(currentPage + 1)}
              disabled={currentPage === totalPages}
            >
              <FaArrowRight />
            </button>
            <button
              onClick={() => handlePageChange(totalPages)}
              disabled={currentPage === totalPages}
            >
              Last
            </button>
          </div>
            <EditModal isOpen={isModalOpen} onClose={handleCloseModal}>
              <OwnerDetailForm
                formData={ownerDetails}
                onSave={handleOwnerFormSave}
                onClear={handleOwnerFormClear}
                mode="edit"
              />
              <EventDetailForm
                formData={eventDetails}
                onSave={handleEventFormSave}
                onClear={handleEventFormClear}
                mode="edit"
              />
              <CateringDetailForm
                formData={cateringDetails}
                NumOfGuest={eventDetails.numOfPersons}
                onSave={handleCateringFormSave}
                onClear={handleCateringFormClear}
                mode="edit"
              />
              <FoodDetailForm
                formData={foodDetails}
                onSave={handleFoodFormSave}
                onClear={handleFoodFormClear}
                mode="edit"
              /> 
              <PaymentsDetail
                  formData= {paymentDetails}
                  totalCharges= {totalCharges}
                  onSave= {handlePaymentsFormSave}
                  onClear= {handlePaymentsFormClear}
                  mode="edit"
                />
            </EditModal>

            <ConfirmModal
              isActive={isConfirmModalActive}
              onConfirm={handleConfirmDelete}
              onCancel={handleCancelDelete}
              title="Confirm Delete"
              message={`Are you sure you want to delete the event "${
                eventToDelete?.eventDetails?.eventType === 'Custom'
                  ? eventToDelete?.eventDetails?.customEventType
                  : eventToDelete?.eventDetails?.eventType
              }"?`}
              subMessage="This action cannot be undone."
            />
          </div>

          
      </>
      )}
    </div>
    
        
  );
};

export default AllEvents;
