import React from 'react'; 

const ConfirmModal = ({ isActive, onConfirm, onCancel, title, message, subMessage }) => {
  return (
    <div className={`modal ${isActive ? 'is-active' : ''}`}>
      <div className="modal-background"></div>
      <div className="modal-card">
        <header className="modal-card-head" style={{ backgroundColor: "white" }}>
          <p className="modal-card-title" style={{ backgroundColor: "white", color: "black" }}>{title}</p>
          <button className="delete" aria-label="close" onClick={onCancel}></button>
        </header>
        <section className="modal-card-body" style={{ backgroundColor: "white" }}>
          <p style={{ color: "black" }}>{message}</p>
          <p style={{ color: "gray" }}>{subMessage}</p>
        </section>
        <footer className="modal-card-foot" style={{ backgroundColor: "white" }}>
          <button type="button"  className="button is-success mx-3" onClick={onConfirm}>Yes</button>
          <button type="button"  className="button" onClick={onCancel}>Cancel</button>
        </footer>
      </div>
    </div>
  );
};

export default ConfirmModal;
