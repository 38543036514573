// Modal.js
import React from 'react';
import 'bulma/css/bulma.css';
import '../../../Styles/EditModal.css';

const EditModal = ({ isOpen, onClose, children }) => {
  if (!isOpen) return null;

  return (
    <div className={`modal ${isOpen ? 'is-active' : ''}`}>
      <div className="modal-background" onClick={onClose}></div>
      <div className="modal-card Edit_Modal_Card">
        <header className="modal-card-head">
          <p className="modal-card-title">Edit Event Details</p>
          <button className="delete" aria-label="close" onClick={onClose}></button>
        </header>
        <section className="modal-card-body Edit_Modal_CardBody">
          {children}
        </section>
      </div>
    </div>
  );
};

export default EditModal;
