import React, { useState, useEffect } from 'react';
import '../../Styles/Dashboard.css';
import { FaCalendarCheck, FaCalendarAlt,FaClock  } from 'react-icons/fa';
import { ClipLoader } from 'react-spinners';
import { Pie, Line, Bar, Doughnut } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
  LineElement,
  CategoryScale,
  LinearScale,
  PointElement,
  BarController
} from 'chart.js';
import BarChart from '../Dashboard/Charts/BarChart';
import EventModal from '../../components/shared/Modal/EventsModal';
import API from "../../services/API";
import { useSelector } from "react-redux";
import{ClearLocalStorageAtMidnight} from '../../Utils/MidnightClear'
import Loader from '../../components/shared/Layout/Loader';
import { fetchAndSetEvents } from '../../Utils/FetchEvents';

ChartJS.register(
  ArcElement,
  Tooltip,
  Legend,
  LineElement,
  CategoryScale,
  LinearScale,
  PointElement
);


const Dashboard = () => {

  const [monthlyEventData, setMonthlyEventData] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [showModal_UpcomingEvent, setShowModal_UpcomingEvent] = useState(false);
  const [showModal_TodayEvents, setShowModal_TodayEvents] = useState(false);
  const [Events, setEvents] = useState([]);
  const { user } = useSelector((state) => state.auth);

  const [activeCount, setActiveCount] = useState(0);
  const [upcomingCount, setUpcomingCount] = useState(0);
  const [todayCount, setTodayCount] = useState(0);

  const now = new Date();
  const currentYear = now.getFullYear();
  const [CurrentYear, setCurrentYear] = useState(currentYear);

  const [activeEvents, setActiveEvents] = useState([]); 
  const [upcomingEvents, setUpcomingEvents] = useState([]);
  const [todayEvents, setTodayEvents] = useState([]);
  const [loading, setLoading] = useState(true);


  
  const normalizeDate = (date) => {
    const newDate = new Date(date);
    newDate.setHours(0, 0, 0, 0);
    return newDate;
  };
  // UseEffect that triggers event data filtering and chart updates
  useEffect(() => {

    if (Events.length === 0){
      return; 
    } 
      
    
    const twoDaysLater = new Date(now);
    twoDaysLater.setDate(now.getDate() + 2);

    const thirtyDaysLater = new Date(now);
    thirtyDaysLater.setDate(now.getDate() + 30);

    // Normalize 'now', 'twoDaysLater', and 'thirtyDaysLater' to midnight
    const normalizedNow = normalizeDate(now);
    const normalizedTwoDaysLater = normalizeDate(twoDaysLater);
    const normalizedThirtyDaysLater = normalizeDate(thirtyDaysLater);

    // Log normalized dates
    // console.log("twoDaysLater:", normalizedTwoDaysLater);
    // console.log("thirtyDaysLater:", normalizedThirtyDaysLater);

    // Filter active events within the next 2 days
    const filteredActiveEvents = Events.filter(event => {
      const eventDate = normalizeDate(new Date(event.eventDetails.eventDay));
      // console.log("eventDate:", eventDate);
      return eventDate > normalizedNow && eventDate <= normalizedTwoDaysLater && event.eventDetails.eventStatus === 'Active';
    });

    // Filter upcoming events within the next 30 days
    const filteredUpcomingEvents = Events.filter(event => {
      const eventDate = normalizeDate(new Date(event.eventDetails.eventDay));
      return eventDate > normalizedNow && eventDate <= normalizedThirtyDaysLater && event.eventDetails.eventStatus === 'Active';
    });


    const today = new Date();
    today.setHours(0, 0, 0, 0);
    // Filter events that are due today and have "Active" status
    const filteredEventsDueToday = Events.filter(event => {
      const eventDate = new Date(event.eventDetails.eventDay);
      eventDate.setHours(0, 0, 0, 0); // Remove time part for comparison
      // console.log("today.getTime():",today.getTime())
      // console.log("eventDate.getTime() :",eventDate.getTime() )
      return eventDate.getTime() === today.getTime() && event.eventDetails.eventStatus === 'Active';
    });

    // Set the filtered active and upcoming events in their respective states
    setActiveEvents(filteredActiveEvents);
    setUpcomingEvents(filteredUpcomingEvents);
    setTodayEvents(filteredEventsDueToday);

    setActiveCount(filteredActiveEvents.length);
    setUpcomingCount(filteredUpcomingEvents.length);
    setTodayCount(filteredEventsDueToday.length);
  
    // Initialize monthly data for chart
    const monthlyData = Array(12).fill(0);
    // const currentYear = now.getFullYear();
    setCurrentYear(currentYear);
  
    // Calculate completed events for the current year
    // console.log('Events:',Events);
    
    Events.forEach(event => {
      const eventDate = new Date(event.eventDetails.eventDay);
      const eventYear = eventDate.getFullYear();
      const eventMonth = eventDate.getMonth();
      // console.log('eventMonth:',eventMonth);
  
      if (event.eventDetails.eventStatus === 'Done' && eventYear === currentYear) {
        monthlyData[eventMonth]++;
        // console.log('monthlyData:',monthlyData);
      }
    });
  
    const chartData = {
      labels: [
        'January', 'February', 'March', 'April', 'May', 'June',
        'July', 'August', 'September', 'October', 'November', 'December'
      ],
      datasets: [
        {
          label: 'Completed Events',
          data: monthlyData,
          backgroundColor: [
            '#FF6384', '#36A2EB', '#FFCE56', '#4BC0C0', '#9966FF', '#FF9F40',
            '#FF6347', '#6B8E23', '#20B2AA', '#8A2BE2', '#D2691E', '#6495ED'
          ],
          borderColor: [
            '#FF6384', '#36A2EB', '#FFCE56', '#4BC0C0', '#9966FF', '#FF9F40',
            '#FF6347', '#6B8E23', '#20B2AA', '#8A2BE2', '#D2691E', '#6495ED'
          ],
          borderWidth: 1,
        }
      ]
    };
  
    setMonthlyEventData(chartData);
  }, [Events]);   
  

  useEffect(() => {
    ClearLocalStorageAtMidnight();
  },[]);

  // Fetch events when user changes or when page switches
  useEffect(() => {
    if (user) {
      // fetchAndSetEvents(); 
      fetchAndSetEvents(user?.UserName, setEvents, setLoading);
    }
  }, [user]);

 
  const handleActiveCountClick = () => {
    setShowModal(true);
  };
  const handleUpcomingCountClick = () => {
    setShowModal_UpcomingEvent(true);
  };
  const handleTodayCountClick = () => {
    setShowModal_TodayEvents(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };
  const handleCloseModal_UpcomingEvent = () => {
    setShowModal_UpcomingEvent(false);
  };
  const handleClose_TodayModal = () => {
    setShowModal_TodayEvents(false);
  };
  


  return (
    <div style={{width:'100%',height:"100vh"}}>
    <h1 className="title my-3 has-text-centered">Admin Dashboard</h1>
      
    {loading ? (
        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
          <Loader />
          <div>
            <p style={{ color: '#222831', fontSize: 'normal', fontWeight:'bold'}}>Please Wait...</p>
          </div>
        </div>
      ) : (
        <>
          {/* Content of your dashboard */}
          <div className="dashboard-container">
            <div className="dashboard-tile is-primary">
              <div className="tile-content">
                <p className="DashboardCardTitle">
                  Active Events <FaCalendarCheck size={20} style={{ marginLeft: "2vh" }} />
                </p>
                <p className="DashboardEventsCount" onClick={handleActiveCountClick}>{activeCount}</p>
                <small className="DashboardEventsText">You have {activeCount} event due in next 2 days</small>
              </div>
            </div>
            <EventModal
              title={"Active Events"}
              isOpen={showModal}
              onClose={handleCloseModal}
              events={activeEvents}
            />

            <div className="dashboard-tile is-info">
              <div className="tile-content">
                <p className="DashboardCardTitle">
                  Upcoming Events <FaCalendarAlt size={20} style={{ marginLeft: "2vh" }} />
                </p>
                <p className="DashboardEventsCount" onClick={handleUpcomingCountClick}>{upcomingCount}</p>
                <small className="DashboardEventsText">You have {upcomingCount} event due in next 30 days</small>
              </div>
            </div>
            <EventModal
              title={"Upcoming Events"}
              isOpen={showModal_UpcomingEvent}
              onClose={handleCloseModal_UpcomingEvent}
              events={upcomingEvents}
            />
          </div>

          <div className="dashboard-container">
            <div className="dashboard-tile is-primary">
              <div className="tile-content">
                <p className="DashboardCardTitle">
                  Today Events <FaClock size={20} style={{ marginLeft: "2vh" }} />
                </p>
                <p className="DashboardEventsCount" onClick={handleTodayCountClick}>{todayCount}</p>
                <small className="DashboardEventsText">You have {todayCount} event due today</small>
              </div>
            </div>
            <EventModal
              title={"Today Events"}
              isOpen={showModal_TodayEvents}
              onClose={handleClose_TodayModal}
              events={todayEvents}
            />
          </div>

          <div className="dashboard-container">
            <div className="dashboard-tile is-danger">
              <div className="tile-content">
                <h2>Completed Events by Month for year {CurrentYear}</h2>
                {monthlyEventData && <Pie data={monthlyEventData} />}
              </div>
            </div>
            <div className="dashboard-tile is-warning">
              <div className="tile-content">
                <h2>Completed Events by Month for year {CurrentYear}</h2>
                {monthlyEventData && (
                  <BarChart
                    dataPoints={{
                      labels: monthlyEventData.labels,
                      data: monthlyEventData.datasets[0].data,
                    }}
                  />
                )}
              </div>
            </div>
          </div>
        </>
      )}

    
    </div>
  );
};

export default Dashboard;
