import { NavLink } from "react-router-dom";
import { FaBars, FaHome, FaHeadset ,FaRegImage ,FaLock, FaMoneyBill,FaSignOutAlt, FaUser, FaPlus, FaPhone, FaList, FaQuestionCircle, FaBell } from "react-icons/fa";
import { useState,useEffect } from "react";
import { AnimatePresence, motion } from "framer-motion";
import SidebarMenu from "./SidebarMenu";
import '../../../Styles/Sidebar.css';
import { useSelector } from "react-redux";
import { useNavigate, useLocation, Link } from "react-router-dom";
import moment from 'moment';
import API from "../../../services/API";
import {  toast } from 'react-toastify';
import { InfoToastConfig } from '../../../components/shared/Forms/ToastConfig';
import logo from '../../../Assets/logo.png';


const routes = [
  {
    path: "/",
    name: "Dashboard",
    icon: <FaHome />,
  },
  {
    path: "/event_forms",
    name: "Add Event",
    icon: <FaPlus />,
  },
  {
    path: "/events_all",
    name: "All Event",
    icon: <FaList />,
  },
  {
    path: "/banner",
    name: "Banner",
    icon: <FaRegImage  />,
  },
  {
    path: "/contact_us",
    name: "Contact Us",
    icon: <FaHeadset  />,
  },
  {
    path: "/tutorial",
    name: "How to Use",
    icon: <FaQuestionCircle />,
  },
  // {
  //   path: "/file-manager",
  //   name: "File Manager",
  //   icon: <AiTwotoneFileExclamation />,
  //   subRoutes: [
  //     {
  //       path: "/settings/profile",
  //       name: "Profile ",
  //       icon: <FaUser />,
  //     },
  //     {
  //       path: "/settings/2fa",
  //       name: "2FA",
  //       icon: <FaLock />,
  //     },
  //     {
  //       path: "/settings/billing",
  //       name: "Billing",
  //       icon: <FaMoneyBill />,
  //     },
  //   ],
  // },
  {
    path: "/notifications",
    name: "Notifications",
    icon: <FaBell />,
  },
  {
    path: "/edit_profile",
    name: "Profile",
    icon: <FaUser />,
  },
  
  // {
  //   path: "/settings",
  //   name: "Settings",
  //   icon: <BiCog />,
  //   exact: true,
  //   subRoutes: [
  //     {
  //       path: "/settings/profile",
  //       name: "Profile ",
  //       icon: <FaUser />,
  //     },
  //     {
  //       path: "/settings/2fa",
  //       name: "2FA",
  //       icon: <FaLock />,
  //     },
  //     {
  //       path: "/settings/billing",
  //       name: "Billing",
  //       icon: <FaMoneyBill />,
  //     },
  //   ],
  // },
  // {
  //   path: "/saved",
  //   name: "Saved",
  //   icon: <AiFillHeart />,
  // },
];

const SideBar = ({ children }) => {
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen(!isOpen);
  const [selectedFile, setSelectedFile] = useState(null);

  const { user } = useSelector((state) => state.auth);
  // Parse the registration date
  const registrationDate = moment(user?.RegistrationDate);

  // Calculate the difference in days between today and the registration date
  const daysSinceRegistration = moment().diff(registrationDate, 'days');
  
    useEffect(() => {
      if (user) {
        // setSelectedFile(user.profilePicture);
        API.get(`/update/profile_picture/${user.BusinessId}`, { responseType: 'blob' })
        .then((response) => {
          // console.log('response.data:',response.data);
          const imageUrl = URL.createObjectURL(response.data);
          setSelectedFile(imageUrl); // Set the fetched image URL
          // console.log('imageUrl:',imageUrl)
        })
        .catch((error) => {
          console.error('Error fetching profile picture:', error);
        });
      }
    }, [user]);
  // console.log("user:",user)
  const navigate = useNavigate();
 
  // Logout handler
const handleLogout = async () => {
  localStorage.clear(); // Clear local storage
  toast.info('Logging Out...', InfoToastConfig);

  // Clear the cache content
  const cacheName = 'my-event-cache'; // Cache name used in your application
  const cache = await caches.open(cacheName);

  // Clear all cache entries in the specified cache
  cache.keys().then(keys => {
    keys.forEach(request => {
      cache.delete(request);
    });
  });

  // Wait for 2 seconds to show the toast, then reload and navigate
  setTimeout(() => {
    window.location.reload(); // Reload the window
    setTimeout(() => {
      navigate("/login"); // Navigate to the login page after reload
    }, 100); // A short delay to allow reload before navigating
  }, 1000); // Delay for toast display
};

  const inputAnimation = {
    hidden: {
      width: 0,
      padding: 0,
      transition: {
        duration: 0.2,
      },
    },
    show: {
      width: "140px",
      padding: "5px 15px",
      transition: {
        duration: 0.2,
      },
    },
  };

  const showAnimation = {
    hidden: {
      width: 0,
      opacity: 0,
      transition: {
        duration: 0.5,
      },
    },
    show: {
      opacity: 1,
      width: "auto",
      transition: {
        duration: 0.5,
      },
    },
  };

  return (
    <>
      <div className="main-container">
        <motion.div
          animate={{
            width: isOpen ? "20%" : "5%",
            transition: {
              duration: 0.5,
              type: "spring",
              damping: 10,
            },
          }}
          className={`sidebar`}
        >
          <div className={`${isOpen ? 'top_section' : 'top_section_Collapsed'}`}>
            <AnimatePresence>
              {isOpen && (
                <motion.h1
                  variants={showAnimation}
                  initial="hidden"
                  animate="show"
                  exit="hidden"
                  className="logo is-hidden-mobile sidebarLogoText"
                >
                  Smart Path
                  {/* PKR-500,000,0 */}
                </motion.h1>
              )}
            </AnimatePresence>

              <div>
                  <p className="is-hidden-desktop is-block-mobile logo sidebarLogoText">Smart Path</p>
              </div>

            <div className="bars">
              <FaBars onClick={toggle} />
            </div>
          </div>
          
          <section className={`${isOpen ? 'routes' : 'routes_closed'}`}>
            {routes.map((route, index) => {
              // for dropdown in routes 
              if (route.subRoutes) {
                return (
                  <SidebarMenu
                    setIsOpen={setIsOpen}
                    route={route}
                    showAnimation={showAnimation}
                    isOpen={isOpen}
                  />
                );
              }

              return (
                <NavLink
                  to={route.path}
                  key={index}
                  className="link"
                  activeClassName="active"
                  onClick={() => {
                    if (window.innerWidth <= 768) {
                      toggle();
                    }
                  }}
                >
                  <div className="icon">{route.icon}</div>
                  <AnimatePresence>
                    {isOpen && (
                      <motion.div
                        variants={showAnimation}
                        initial="hidden"
                        animate="show"
                        exit="hidden"
                        className="link_text"
                      >
                        {route.name}
                      </motion.div>
                    )}
                  </AnimatePresence>
                </NavLink>
              );
            })}
          </section>
          
          <div className={`sidebar-footer ${isOpen ? 'sidebar-footer' : 'sidebarFooterClosed'}`}>  
            <div className={`${isOpen ? 'Footer_profileSection' : 'Footer_profileSection-closed'}`}>
            <div>
              {/* Conditionally render the profile picture or the default icon */}
              {selectedFile ? (
                <img src={selectedFile} alt="Avatar" className="Footer_UserProfilePic" />
              ) : (
                <FaUser  style={{fontSize:'5vh'}}/> // Use an icon as a fallback
              )}
          
            </div>

              <div className="Footer_profileName">
                <p className="Footer_UserName">{user?.BusinessName}</p>
               
                {daysSinceRegistration <= 30 ? (
                  <span className="Footer_FreeTrialBadge">Free Trial</span>
                ) : (
                  <p className="Footer_UserBadge">Admin</p>
                )}

              </div>
                
              
            </div>

            <div>
              <FaSignOutAlt style={{cursor:"pointer"}} onClick={handleLogout}/>
            </div>
          </div>
        </motion.div>

        <main className="EMS_Children_Section">{children}</main>
        
      </div>
      
    </>
  );
};

export default SideBar;
