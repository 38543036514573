import React,{useState,Suspense} from 'react'
import FormField from '../../components/shared/Forms/FormField'; 
import { Font,PDFDownloadLink, PDFViewer, Page, Text, View, Document, StyleSheet,Image  } from '@react-pdf/renderer';
// import 'bulma/css/bulma.css';
import { AiOutlineDownload } from 'react-icons/ai';

import GreatVibes from "../../Assets/fonts/GreatVibes-Regular.ttf";
import Headingstyle from '../../Assets/headingstyle.jpg'


import BaratTopLeftImage from '../../Assets/PDFBanner1.png';
import BaratBottomRightImage from '../../Assets/PDFBanner2.png';

import MehndiBottomRightImage from '../../Assets/MehndiBanner1.png';
import MehndiTopLeftImage from '../../Assets/MehndiBanner2.png';

import WalimaTopLeftImage from '../../Assets/WalimaBanner1.png';
import WalimaBottomRightImage from '../../Assets/WalimaBanner2.png';

 
Font.register({
family: 'GreatVibes', fonts: [{ src: GreatVibes}],
format: 'truetype',
fontWeight:400
});

const EventBanner = () => {
    const [BannerData, setBannerData] = useState({
        groomName: '',
        bridalName: '',
        EventOwnerName:'',
        eventType:'',
        customEventType:'',
      });
      const handleChange = (e) => {
        const { name, value } = e.target;
        setBannerData({
          ...BannerData,
          [name]: value
        });
      };
      const styles = StyleSheet.create({
        page: {
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          backgroundColor: '#ffffff',
          width: '100%',
          height: '100vh',
        },
        groomName: {
          fontSize: 50,
        //   fontWeight: 'bold',
          textAlign: 'center',
          fontFamily: "GreatVibes",
          marginBottom: 10,
        },
        namesSection:{
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',

        },
        bridalName: {
          fontSize: 50,
          textAlign: 'center',
          fontFamily: "GreatVibes",
          marginTop: 10,
        },
        wedsText: {
          fontSize: 60,
          textAlign: 'center',
          fontFamily: "GreatVibes",
          marginBottom: 20,
        },
     
        eventOwner: {
          position: 'absolute',
          bottom: 30,
          fontSize: 20,
          textAlign: 'center',
          fontFamily: "GreatVibes",
        },
        eventType: {
            position: 'absolute',
            bottom: 30,
            left:19,
            fontSize: 20,
            textAlign: 'center',
            fontFamily: "GreatVibes",

          },
        isdisabled: {
            opacity: 0.5,
            pointerEvents: 'none', 
        },
        topLeftImage: {
            position: 'absolute',
            top: 10,
            left: -30,
            width: '40vh', 
            height: '40vh', 
          },
          bottomRightImage: {
            position: 'absolute',
            bottom: 10,
            right: 0,
            width: '40vh', 
            height: '40vh',
          },
          centerHeadingStyle:{
            position: 'absolute',
            bottom: '65vh',
            // right: 0,
            width: '85vh', 
            height: '30vh',
            
          },
      });
   
const MyDocument = ({ groomName, bridalName, eventOwner, eventType }) => {

let topLeftImageSrc;
let bottomRightImageSrc;

switch (eventType) {
    case 'Barat':
    topLeftImageSrc = BaratTopLeftImage;
    bottomRightImageSrc = BaratBottomRightImage;
    break;
    case 'Walima':
    topLeftImageSrc = WalimaTopLeftImage;
    bottomRightImageSrc = WalimaBottomRightImage;
    break;
    case 'Mehndi':
    topLeftImageSrc = MehndiTopLeftImage;
    bottomRightImageSrc = MehndiBottomRightImage;
    break;
    // default:
    // topLeftImageSrc = '/path/to/default-top-left-image.png';  // Fallback image
    // bottomRightImageSrc = '/path/to/default-bottom-right-image.png';  // Fallback image
}

return (
    <Document>
    <Page size="A4" orientation="landscape" style={styles.page}>
        {/* Top-left image */}
        <Image
            src={topLeftImageSrc}
            style={styles.topLeftImage}
        /> 
        
        {/* Center heading style */}
        <Image
            src={Headingstyle}
            style={styles.centerHeadingStyle}
        />
        
        {/* Names and texts section */}
        <View style={styles.namesSection}>
            <Text style={styles.groomName}>{groomName}</Text>
            <Text style={styles.wedsText}>Weds</Text>
            <Text style={styles.bridalName}>{bridalName}</Text>
        </View>
        
        {/* Bottom-right image */}
        <Image
            src={bottomRightImageSrc}
            style={styles.bottomRightImage}
        />
        
        {/* Event Type or Custom Event Type */}
        <Text style={styles.eventType}>
            {/* Event: {eventType === 'Custom' ? customEventType : eventType} */}
            Event: {eventType}
        </Text>

        {/* Event Owner */}
        <Text style={styles.eventOwner}>Event By: {eventOwner}</Text>
    </Page>
    </Document>
);
};


const isFormValid = BannerData.groomName && BannerData.bridalName && BannerData.EventOwnerName;
return (
    <div style={{ width: '60%', height: '100vh' }}>
      <h1 className="title my-3">Event Banner</h1>
      <div className="columns">
        <FormField
          label="Groom Name"
          type="text"
          name="groomName"
          value={BannerData.groomName}
          onChange={handleChange}
          required={true}
        />
        <FormField
          label="Bridal Name"
          type="text"
          name="bridalName"
          value={BannerData.bridalName}
          onChange={handleChange}
          required={true}
        />
      </div>
      <div className="columns">
            <FormField
            label="Event Owner"
            type="text"
            name="EventOwnerName"
            value={BannerData.EventOwnerName}
            onChange={handleChange}
            required={true}
            />
             <div className="column">
              <div className="field">
                <label className="EMS_Add_Event_Label">
                  Event Type <span className="has-text-danger">*</span>
                </label>
                <div className="control">
                  <div className="select">
                    <select
                      name="eventType"
                      value={BannerData.eventType}
                      onChange={handleChange}
                      required
                      style={{ backgroundColor: "white", color: 'black' }}
                    >
                      <option value="">Select Event Type</option>
                      <option value="Mehndi">Mehndi</option>
                      <option value="Barat">Barat</option>
                      <option value="Walima">Walima</option>
                      {/* <option value="Awards Ceremony">Awards Ceremony</option>
                      <option value="Custom">Custom</option> */}
                    </select>
                  </div>
                  {BannerData.eventType === 'Custom' && (
                    <input
                    type="text"
                    className='EMS_Add_Event_input input CustomInputEventDetail'
                    style={{width:'-webkit-fill-available',margin:'1vh 0 0 0'}}
                    name="customEventType"
                    value={BannerData.customEventType}
                    onChange={handleChange}
                    placeholder="Custom Event Type"
                    required
                    />
                  )}
                </div>
              </div>
            </div>
      </div>


    {/* PDF Download Link */}
    <div style={{ marginTop: '20px' }}>
         {isFormValid ? (
                    <PDFDownloadLink
                        document={<MyDocument
                            groomName={BannerData.groomName}
                            bridalName={BannerData.bridalName}
                            eventOwner={BannerData.EventOwnerName}
                            eventType={BannerData.eventType}
                            customEventType={BannerData.customEventType}
                        />}
                        fileName="Banner.pdf"
                    >
                        {({ loading }) => (
                            <div className='button'>
                                {loading ? 'Loading document...' : 'Download'}
                                <AiOutlineDownload style={{fontSize:"4vh",padding:'0 0 0 2vh'}}/>
                            </div>
                        )}
                    </PDFDownloadLink>
                ) : (
                    <div className={`button`} style={styles.isdisabled}>
                        Download PDF
                    </div>
                )}
      </div>

      {/* PDF Viewer - To preview the PDF */}
      <div style={{ marginTop: '20px', height: '400px' }}>
        <PDFViewer width="100%" height="400px">
          <MyDocument
            groomName={BannerData.groomName}
            bridalName={BannerData.bridalName}
            eventOwner={BannerData.EventOwnerName}
            eventType={BannerData.eventType}
            customEventType={BannerData.customEventType}
          />
        </PDFViewer>
      </div>

    
    </div>
  );
}

export default EventBanner
