import React, { useState,useEffect } from 'react';
import ToggleSwitch from '../NotificationComponent/ToggleSwitch';
import ConfirmModal from '../../components/shared/Modal/Modal';
import { useSelector } from "react-redux";
import API from "../../services/API";
import{ClearLocalStorageAtMidnight} from '../../Utils/MidnightClear'


const Notifications = () => {
  // const [isToggled, setIsToggled] = useState(user?.EmailNotification);
  // const [showModal, setShowModal] = useState(false);
  const { user } = useSelector((state) => state.auth);

  const [isToggled, setIsToggled] = useState(user?.emailNotification === 'On');
  const [showModal, setShowModal] = useState(false);

  
  useEffect(() => {
    ClearLocalStorageAtMidnight();
  },[]);
  // Sync toggle state with EmailNotification if user changes
  useEffect(() => {
    // console.log('user:',user)
    // console.log('user?.EmailNotification:',user?.emailNotification)
    setIsToggled(user?.emailNotification === 'On');
  }, [user]);

  const handleToggle = () => {
    if (isToggled) {
      // Show confirmation modal before turning off notifications
      setShowModal(true);
    } else {
      // Directly turn on notifications
      updateNotificationPreference('On');
      setIsToggled(true);
    }
  };

  const handleConfirm = () => {
    // Turn off notifications after confirmation
    updateNotificationPreference('Off');
    setIsToggled(false);
    setShowModal(false);
  };

  const handleCancel = () => {
    setShowModal(false);
  };

  const updateNotificationPreference = (newPreference) => {
    API.put(`/auth/update-email-notification/${user?.BusinessId}`, { emailNotification: newPreference })
      .then((response) => {
        console.log('Notification preference updated successfully');
      })
      .catch((error) => {
        console.error('Error updating notification preference', error);
      });
  };

  return (
    <>
      <h1 className="title my-3">Notifications</h1>

      <div className="box ToggleBox">
        <span style={{ color: "black" }}>Turn email notifications on/off</span>
        <ToggleSwitch isOn={isToggled} handleToggle={handleToggle} />
      </div>

      <ConfirmModal
        isActive={showModal}
        onConfirm={handleConfirm}
        onCancel={handleCancel}
        title="Confirm Action"
        message="Are you sure you want to turn off email notifications?"
        subMessage="You will not get notifications for the events that are due."
      />
    </>
  );
};

export default Notifications;
