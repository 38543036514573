import React, { useState, useEffect } from 'react';
import 'bulma/css/bulma.css';
import '../../../Styles/OwnerDetailForm.css';
import FormField from './FormField';
import StaticTimePickerLandscape from './DateCom';
import dayjs from 'dayjs';
import {  toast } from 'react-toastify';
import { ToastConfig } from '../../shared/Forms/ToastConfig';
import ConfirmModal from '../Modal/Modal';
import { useSelector } from "react-redux";

const EventDetailForm = ({ onNext, onEdit, onSave, onClear, formData: initialFormData, mode }) => {

  const { user } = useSelector((state) => state.auth);

  const [formData, setFormData] = useState({
    numOfPersons: '',
    ratePerGuest:'',
    cost: 0,
    eventType: '',
    customEventType: '',
    eventDay: '',
    hallNo: '',
    // eventTime: dayjs().format('HH:mm'),
    eventTime: '',
    Description: ''
  });


  useEffect(() => {
    if (initialFormData) {
      const formattedDate = initialFormData.eventDay
        ? new Date(initialFormData.eventDay).toISOString().split('T')[0]
        : '';
        
      setFormData({
        ...initialFormData,
        eventDay: formattedDate
      });
      setPreviousSelectedDate(formattedDate);
    }
  }, [initialFormData]);
  
  
  useEffect(() => {
    const numOfPersons = parseInt(formData.numOfPersons) || 0;
    const ratePerGuest = parseFloat(formData.ratePerGuest) || 0;
    const computedCost = numOfPersons * ratePerGuest;
  
    // Update the cost in the formData state
    setFormData((prevFormData) => ({
      ...prevFormData,
      cost: computedCost
    }));
  }, [formData.numOfPersons, formData.ratePerGuest]);

  const [isModalActive, setIsModalActive] = useState(false);
  const [isDuplicate, setIsDuplicate] = useState(false);
  const [pendingDate, setPendingDate] = useState(null);
  const [previousSelectedDate, setPreviousSelectedDate] = useState(null);

  const checkSameEventDate=async (selectedDate)=>{
       // Check if an event already exists on this date
       const cacheName = 'my-event-cache';  // Cache name used to store events
       const cacheKey = `events-${user?.UserName}-All`;  // Cache key
       const cache = await caches.open(cacheName);
       const cachedResponse = await cache.match(cacheKey);
 
       if (cachedResponse) {
         const cachedData = await cachedResponse.json();
        const selectedDay = dayjs(selectedDate).startOf('day');  // Start of day removes time for accurate comparison

        const isDuplicate = cachedData.some((event) => {
          // Convert eventDay from cached event data to Day.js object and ignore time for comparison
          const eventDay = dayjs(event.eventDetails.eventDay).startOf('day');  // Compare only date, ignore time
          // console.log('eventDay:',eventDay);
          return eventDay.isSame(selectedDay, 'day');
        });

        //  console.log('selectedDate:',selectedDate);
        //  console.log('isDuplicate:',isDuplicate)
         if (isDuplicate) {
            setIsDuplicate(true); 
           return isDuplicate;
         }
         else{
          return false;
         }
         
       }
  }
  // Handle eventDay change
  const handleChange = (event) => {
    const { name, value, type } = event.target;

    if (name === 'eventDay') {
      const selectedDate = dayjs(value);
      const currentDate = dayjs();

      if (selectedDate.isBefore(currentDate, 'day')) {
        setPendingDate(value);  // Save the selected date to state
        setIsModalActive(true); // Open the modal to alert past  date event
      } else {
        const isdup = checkSameEventDate(selectedDate);
        // console.log('isdup:',isdup);
        
           setFormData({
          ...formData,
          eventDay: value,
          eventStatus: 'Active', // Set status to Active for future or current dates
        });
        
        if (onEdit) onEdit();
      }
      return;
    }

    // Handle negative numbers
    if (type === 'number') {
      const numericValue = parseFloat(value); // Convert value to a number
  
      // Check for negative values
      if (numericValue <= 0) {
        toast.error("Value cannot be negative or zero", ToastConfig);
        return;
      }
    }

    // Reset customEventType if a predefined event is selected
    if (name === 'eventType' && value !== 'Custom') {
      setFormData({ ...formData, eventType: value, customEventType: '' });
      // onEdit();
      if (onEdit) onEdit();

    } else {
      // Update state for other inputs
      setFormData({ ...formData, [name]: value });
      // onEdit();
      if (onEdit) onEdit();

    }
  };

  // Modal confirm handler
  const handleConfirm = () => {
    setFormData({
      ...formData,
      eventDay: pendingDate,
      eventStatus: 'Done', // Set status to Done for past dates
    });
    // onEdit();
    if (onEdit) onEdit();
    setIsModalActive(false); // Close the modal
  };

  // Modal cancel handler
  const handleCancel = () => {
    setFormData({
      ...formData,
      eventDay: formData.eventDay,
    });
    setIsModalActive(false); // Close the modal without making changes
  };

  const handleDuplicateConfirm = () => {
    if (onEdit) onEdit();
    setIsDuplicate(false); // Close the modal
  };

  // Modal cancel handler
  const handleDuplicateCancel = () => {
    if (onSave) {
      // console.log('previousSelectedDate:',previousSelectedDate)
      setFormData({
        ...formData,
        eventDay: previousSelectedDate,
      });
    }
    else{
      setFormData({
        ...formData,
        eventDay: '',
      });
    }
    setIsDuplicate(false); // Close the modal without making changes
  };

  const handleTimeChange = (newValue) => {
    setFormData({
      ...formData,
      eventTime: newValue.format('HH:mm'),
    });
    // onEdit();
    if (onEdit) onEdit();
  };

  const handleClear = () => {
    setFormData({
      numOfPersons: '',
      ratePerGuest:'',
      cost: 0,
      eventType: '',
      customEventType: '',
      eventDay: '',
      eventTime: '',
      hallNo: '', 
      // eventTime: dayjs().format('HH:mm'), 
      Description: '',
    });
    onClear();
  };
    
    const [isSubmitting, setIsSubmitting] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const { numOfPersons, eventType, eventDay, eventTime } = formData;

    if (numOfPersons && eventType && eventDay && eventTime) {
      setIsSubmitting(true); // Disable the button during submission
      try {
        if (mode === 'add') {
          onNext(formData); // Ensure that the next action is awaited
        } else {
          await onSave(formData); // Await the save action
        }
      } catch (error) {
        console.log('Error:', error);
      } finally {
        setIsSubmitting(false); // Re-enable the button after the request finishes
      }
    } else {
      console.log('Please fill all required fields');
    }
  };

  return (
    <div className="container" style={{ marginTop: "8%" }}>
      <div className="EMS_Add_Event_Box box">
        <h1 className="title">Event Details</h1>
        <form onSubmit={handleSubmit}>
          <div className="columns my-0">
            <FormField
              label="Number of Guests"
              type="number"
              name="numOfPersons"
              value={formData.numOfPersons}
              onChange={handleChange}
              required={true}
            />
            <FormField
              label="Rate per Guest"
              type="number"
              name="ratePerGuest"
              value={formData.ratePerGuest}
              onChange={handleChange}
              // required={true}
            />
             
          </div>          
          <p className="cost-display" style={{ color: '#888', fontSize: '0.9em', marginBottom: '1em' }}>
              Guests Charges: {formData.cost.toFixed(2)} ({formData.numOfPersons} x {formData.ratePerGuest})
          </p>

          <div className='columns'>
            <div className="column">
              <div className="field">
                <label className="EMS_Add_Event_Label">
                  Event Type <span className="has-text-danger">*</span>
                </label>
                <div className="control">
                  <div className="select">
                    <select
                      name="eventType"
                      value={formData.eventType}
                      onChange={handleChange}
                      required
                      style={{ backgroundColor: "white", color: 'black' }}
                    >
                      <option value="">Select Event Type</option>
                      <option value="Mehndi">Mehndi</option>
                      <option value="Barat">Barat</option>
                      <option value="Walima">Walima</option>
                      <option value="Awards Ceremony">Awards Ceremony</option>
                      <option value="Custom">Custom</option>
                    </select>
                  </div>
                  {formData.eventType === 'Custom' && ( // Conditionally render text field
                    <input
                    type="text"
                    className='EMS_Add_Event_input input CustomInputEventDetail'
                    // style={{width:"22%"}}
                    name="customEventType"
                    value={formData.customEventType}
                    onChange={handleChange}
                    placeholder="Event Type"
                    required
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
          
          <div className="columns">
            <FormField
              label="Event Day"
              type="date"
              name="eventDay"
              value={formData.eventDay}
              onChange={handleChange}
              required={true}
            />
            <FormField
              label="Hall No."
              type="text"
              name="hallNo"
              value={formData.hallNo}
              onChange={handleChange}
            />
          </div>
            

          <div className="columns">
              <StaticTimePickerLandscape
                value={dayjs(formData.eventTime, 'HH:mm')}
                onChange={handleTimeChange}
                className='date_picker'
              />
          </div>
          <div className="field">
              <FormField
                label="Any other thing"
                type="text"
                name="Description"
                value={formData.Description}
                onChange={handleChange}
                placeholder="Any other thing you can add here"
                isTextarea={true}
              />
          </div>
           {/* Confirm Modal */}
           <ConfirmModal
            isActive={isModalActive}
            onConfirm={handleConfirm}
            onCancel={handleCancel}
            title="Past Event Date"
            message="You have selected a past date for the event."
            subMessage="Are you sure you want to create this event with a past date?"
          />
          <ConfirmModal
            isActive={isDuplicate}
            onConfirm={handleDuplicateConfirm}
            onCancel={handleDuplicateCancel}
            title="Event Already Exist"
            message="You have selected a date on which there is already an event booked."
            subMessage="Are you sure you want to create this event with a different time?"
          />
          
          <div className="field is-grouped">
            <div className="control">
              <button type="button" className="button is-light" onClick={handleClear}>Clear Fields</button>
            </div>
            <div className="control">
              {/* <button type="submit" className="button btn-bg">{mode === 'add' ? 'Next' : 'Save'}</button> */}
              <button
                type="submit"
                className="button btn-bg"
                disabled={isSubmitting} // Disable the button during submission
              >
                {isSubmitting ? 'Saving...' : (mode === 'add' ? 'Next' : 'Save')}
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default EventDetailForm;
