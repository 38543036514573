import React, { useState,useEffect, useRef } from 'react';
import OwnerDetailForm from '../../components/shared/Forms/OwnerDetailForm';
import EventDetailForm from '../../components/shared/Forms/EventDetailForm';
import '../../Styles/FormsContainer.css';
import CateringDetailForm from '../../components/shared/Forms/CateringDetailForm';
import FoodDetailForm from '../../components/shared/Forms/FoodDetailForm';
import PaymentsDetail from '../../components/shared/Forms/PaymentsDetail';
import API from "../../services/API";
import { useSelector } from "react-redux";
import {  toast } from 'react-toastify';
import { ToastConfig,SuccessToastConfig } from '../../components/shared/Forms/ToastConfig';
import{ClearLocalStorageAtMidnight} from '../../Utils/MidnightClear'

// const {
//   filterCheckedDecorItems,
//   filterCheckedFoodDetails,
//   filterPaymentsDetails,
// } = require('../../Utils/DataFilters');
import { filterCheckedDecorItems, filterCheckedFoodDetails, filterPaymentsDetails } from '../../Utils/DataFilters';


const FormsContainer = () => {
  const { user } = useSelector((state) => state.auth);
  const [isOwnerFormComplete, setIsOwnerFormComplete] = useState(false);
  const [isEventDetailFormComplete, setIsEventDetailFormComplete] = useState(false);
  const [isCateringDetailFormComplete, setIsCateringDetailFormComplete] = useState(false);
  const [isFoodDetailFormComplete, setIsFoodDetailFormComplete] = useState(false);
  const [isPaymentDetailFormComplete, setIsPaymentDetailFormComplete] = useState(false);
  const [guestsCost, setGuestsCost] = useState(0);
  const [NumOfGuest, setNumOfGuest] = useState(0);
  const [allFormData, setAllFormData] = useState({
    ownerFormData: {},
    eventFormData: {},
    cateringFormData: {},
    foodFormData: {},
    paymentFormData: {},
    totalCost: 0,
  });  
  const eventFormRef = useRef(null);
  const cateringFormRef = useRef(null);
  const foodFormRef = useRef(null);
  const paymentFormRef = useRef(null);
  const [isFormSubmissionComplete, setIsFormSubmissionComplete] = useState(false);
  

  
  useEffect(() => {
    ClearLocalStorageAtMidnight();
  },[]);
  useEffect(() => {
    if (isFormSubmissionComplete) {
      sendFormData(allFormData);
    }
  }, [isFormSubmissionComplete]); 

  
  const resetFormCompletion = (formName) => {
    if (formName === 'owner') {
      // setIsEventDetailFormComplete(false);
      // setIsCateringDetailFormComplete(false);
      // setIsFoodDetailFormComplete(false);
      // setIsPaymentDetailFormComplete(false);
         setIsOwnerFormComplete(false);

    } else if (formName === 'event') {
      // setIsCateringDetailFormComplete(false);
      // setIsFoodDetailFormComplete(false);
      // setIsPaymentDetailFormComplete(false);
    setIsEventDetailFormComplete(false);
    } else if (formName === 'catering') {
      // setIsFoodDetailFormComplete(false);
      // setIsPaymentDetailFormComplete(false);
         setIsCateringDetailFormComplete(false);
    } else if (formName === 'food') {
      // setIsPaymentDetailFormComplete(false);
         setIsFoodDetailFormComplete(false);
    }
  };


    // Clear Handlers 
  const handleOwnerFormClear = () => {
    setIsOwnerFormComplete(false);
    // setIsEventDetailFormComplete(false);
    // setIsCateringDetailFormComplete(false);
    // setIsFoodDetailFormComplete(false);
  };
  const handleEventFormClear = () => {
    setIsEventDetailFormComplete(false);
    // setIsCateringDetailFormComplete(false);
    // setIsFoodDetailFormComplete(false);
  };
  const handleCateringFormClear = () => {
    // setIsEventDetailFormComplete(false);
    setIsCateringDetailFormComplete(false);
    // setIsFoodDetailFormComplete(false);
  };
  const handleFoodFormClear = () => {
    setIsFoodDetailFormComplete(false);
  };
  
    // Edit Handlers
    const handleOwnerFormEdit = () => {
      resetFormCompletion('owner');
    };
  
    const handleEventFormEdit = () => {
      resetFormCompletion('event');
    };
  
    const handleCateringFormEdit = () => {
      resetFormCompletion('catering');
    };
  
    const handleFoodFormEdit = () => {
      resetFormCompletion('food');
    };

    // Next Handlers 
  const handleOwnerFormNext = (OwnerFormData) => {
    setAllFormData(prevData => ({
      ...prevData,
      ownerFormData: OwnerFormData
    }));
    setIsOwnerFormComplete(true);
    eventFormRef.current.scrollIntoView({ behavior: 'smooth' });
  };
  
  const handleEventFormNext = (EventFormData) => {
    allFormData.totalCost = 0;
    setGuestsCost(EventFormData.cost);
    setNumOfGuest(EventFormData.numOfPersons);
    setAllFormData(prevData => ({
      ...prevData,
      eventFormData: EventFormData,
      totalCost: prevData.totalCost + EventFormData.cost
    }));
    setIsEventDetailFormComplete(true);
    cateringFormRef.current.scrollIntoView({ behavior: 'smooth' });
  };
  
  const handleCateringFormNext = (CateringFormData) => {
    setAllFormData(prevData => ({
      ...prevData,
      cateringFormData: CateringFormData,
      totalCost: guestsCost + CateringFormData.DecorCost
    }));
    setIsCateringDetailFormComplete(true);
    foodFormRef.current.scrollIntoView({ behavior: 'smooth' });
  };
  
  const handleFoodFormNext = (foodFormData) => {
    // console.log('foodFormData:',foodFormData)
    setAllFormData(prevData => ({
      ...prevData,
      foodFormData: foodFormData
    }));
    setIsFoodDetailFormComplete(true);
    paymentFormRef.current.scrollIntoView({ behavior: 'smooth' });
  };
 
  const handlePaymentFormNext = (paymentFormData) => {
    // console.log('paymentFormData:', paymentFormData);
    setAllFormData((prevData) => ({
      ...prevData,
      paymentFormData: paymentFormData,
    }));
    setIsFormSubmissionComplete(true); // Set to true to indicate form submission is complete
  };
  const sendFormData = async (formData) => {
    try {
      formData.cateringFormData = filterCheckedDecorItems(formData.cateringFormData);
      formData.foodFormData = filterCheckedFoodDetails(formData.foodFormData);
      formData.paymentFormData = filterPaymentsDetails(formData.paymentFormData);
  
      // console.log("user?.BusinessName:",user?.BusinessName)

      // Send form data to the server
      const { data } = await API.post(`/eventForms/addEventForms/${user?.UserName}`, formData);
  
      if (data?.success) {
        // Get the newly added event from the response
        const newEvent = data.event; // Ensure your API response includes the new event data
  
        // Update the cache
        const cacheName = 'my-event-cache'; // Define a unique cache name
        const cacheKey = `events-${user?.UserName}-All`; // Key to identify cache
        const cache = await caches.open(cacheName);
        const cachedResponse = await cache.match(cacheKey);
  
        let updatedEvents = [];
  
        if (cachedResponse) {
          const cachedData = await cachedResponse.json();
          // Separate cached data into "Active" and "Done" events
          const activeEvents = cachedData.filter(event => event.eventDetails.eventStatus === 'Active');
          const doneEvents = cachedData.filter(event => event.eventDetails.eventStatus === 'Done');
  
          // Add the new event to the appropriate category
          if (newEvent.eventDetails.eventStatus === 'Active') {
            activeEvents.push(newEvent);
          } else {
            doneEvents.push(newEvent);
          }
  
          // Sort each category by event day (most recent first)
          const sortedActiveEvents = activeEvents.sort((a, b) => new Date(a.eventDetails.eventDay) - new Date(b.eventDetails.eventDay));
          const sortedDoneEvents = doneEvents.sort((a, b) => new Date(a.eventDetails.eventDay) - new Date(b.eventDetails.eventDay));
  
          // Combine the sorted arrays with "Active" events first
          updatedEvents = [...sortedActiveEvents, ...sortedDoneEvents];
        } else {
          // If no cached data, initialize with the new event
          updatedEvents = [newEvent];
        }
  
        // Update the cache with the new list of events
        const response = new Response(JSON.stringify(updatedEvents)); // Create a Response object
        await cache.put(cacheKey, response); // Store updated data in Cache Storage
  
        // Update the state with the new list of events
        setAllFormData(prevData => ({
          ...prevData,
          events: updatedEvents
        }));
  
        // Show success toast
        toast.success("Details Saved successfully!",SuccessToastConfig);

        const emailPromises = [];

        // Send email to event owner if email exists
        if (formData.ownerFormData.email) {
          emailPromises.push(
            API.post('/emailProcedures/sendEventAddedEmailToCustomer', {
              email: formData.ownerFormData.email,
              BusinessName:user?.BusinessName,
              eventDetails: {
                ownerName: formData.ownerFormData.ownerName,
                eventType: formData.eventFormData.eventType === 'Custom' 
                ? formData.eventFormData.customEventType 
                : formData.eventFormData.eventType, 
                eventStatus: formData.eventFormData.eventStatus,
                eventDay: formData.eventFormData.eventDay,
                eventTime: formData.eventFormData.eventTime,
                managerPhone:user?.phone,
              }
            })
          );
        }
  
        // Send email to hall owner if email exists
        if (user?.emailNotification === "On") {
          if (user?.email) {
            emailPromises.push(
            API.post('/emailProcedures/sendEventAddedEmailToHallOwner', {
              email: user?.email,
              eventDetails: {
                ownerName: formData.ownerFormData.ownerName,
                eventType: formData.eventFormData.eventType === 'Custom' 
                ? formData.eventFormData.customEventType 
                : formData.eventFormData.eventType, 
                eventStatus: formData.eventFormData.eventStatus,
                eventDay: formData.eventFormData.eventDay,
                eventTime: formData.eventFormData.eventTime,
                customerPhone: formData.ownerFormData.phone ? formData.ownerFormData.phone : undefined 
              }
            })
          );
        }
      }
  
        // Send emails in the background and don't wait for them to complete
        emailPromises.forEach(promise => promise.catch(error => console.error('Error sending email:', error)));
  
        // Reload the page after a 2-second delay
        setTimeout(() => {
          window.location.reload();
        }, 1000);
       
  
      } else {
        toast.error("Event Details not Saved. Try again later", ToastConfig);
      }
    } catch (error) {
      console.log('Error in API call:', error);
    }
  };
  
 
  return (
    <div className="EMS_Forms_Container">
      <OwnerDetailForm onNext={handleOwnerFormNext}  onEdit={handleOwnerFormEdit} onClear={handleOwnerFormClear} mode="add"/>

      {/* EventDetailForm */}
      <div ref={eventFormRef} className={isOwnerFormComplete ? 'event-form-visible' : 'event-form-dim'}>
        <EventDetailForm onNext={handleEventFormNext}  onEdit={handleEventFormEdit} onClear={handleEventFormClear} mode="add"/>
      </div>

      {/* CateringDetailForm */}
      <div ref={cateringFormRef} className={isEventDetailFormComplete && isOwnerFormComplete ? 'event-form-visible' : 'event-form-dim'}>
        <CateringDetailForm onNext={handleCateringFormNext}  onEdit={handleCateringFormEdit} onClear={handleCateringFormClear} mode="add" NumOfGuest={NumOfGuest}/>
      </div>

       {/* FoodDetailForm */}
       <div ref={foodFormRef} className={isOwnerFormComplete && isEventDetailFormComplete && isCateringDetailFormComplete 
                    ? 'event-form-visible' : 'event-form-dim'}>
        <FoodDetailForm onNext={handleFoodFormNext} onEdit={handleFoodFormEdit} onClear={handleFoodFormClear} mode="add"/>
      </div>

      {/* PaymentsDetailForm */}
      <div ref={paymentFormRef} className={isOwnerFormComplete && isEventDetailFormComplete && isCateringDetailFormComplete && isFoodDetailFormComplete 
                    ? 'event-form-visible' : 'event-form-dim'}>
        <PaymentsDetail onNext={handlePaymentFormNext} mode="add" totalCharges={allFormData.totalCost}/>
      </div>

    </div>
  );
};

export default FormsContainer;
