import React, { useEffect, useState } from 'react';
import { createAsyncThunk } from "@reduxjs/toolkit";
import API from "../../../services/API";
import { toast } from "react-toastify";
import { ToastConfig,SuccessToastConfig } from '../../../components/shared/Forms/ToastConfig';
import {  handleLogin } from "../../../services/authService";


export const userLogin = createAsyncThunk(
  "auth/login",
  async ({ UserName, password }, { rejectWithValue }) => {
    try {
      const { data } = await API.post("/auth/login", { UserName, password });
      //store token
      if (data?.success) {
        // alert(data.message);
        localStorage.setItem("token", data.token);
        toast.success('Login successful!', SuccessToastConfig);
        setTimeout(() => {
          window.location.replace("/"); 
        }, 2000); 
      }
      else if (data?.success == false) {
        toast.error(`${data?.message}`,ToastConfig);
      }

      return data;
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);
export const userLoginViaEmail = createAsyncThunk(
  "auth/loginViaEmail",
  async ({ Email }, { rejectWithValue }) => {
    try {
      // console.log('Email:',Email)
      const { data } = await API.post("/auth/loginViaEmail", { Email });
      // console.log('data:',data)
      //store token
      if (data?.success) {
        // alert(data.message);
        localStorage.setItem("token", data.token);
        toast.success('Login successful!', SuccessToastConfig);
        setTimeout(() => {
          window.location.replace("/"); 
        }, 2000); 
      }
      else if (data?.success == false) {
        toast.error(`${data?.message}`,ToastConfig);
      }

      return data;
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const userRegister = createAsyncThunk(
  "auth/register",
  async (
    {
      BusinessName,
      UserName,
      email,
      location,
      phone,
      password,
    },
    { rejectWithValue }
  ) => {
    try {
      const { data } = await API.post("/auth/register", {
        BusinessName,
        UserName,
        email,
        location,
        phone,
        password,
      });
      // console.log('BusinessName:',BusinessName)
      if (data?.success) {
        toast.success('Registration successful!', {
          position: "top-right",
          hideProgressBar: true,
          autoClose: 3000, 
          className: 'custom-toast-success',
          bodyClassName: 'custom-toast-body', 
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
               
        setTimeout(() => {
        window.location.replace("/login");
        }, 1000); // 3000 milliseconds = 3 seconds
        
      }
      else if(data?.success == false && data?.message?.includes('Email Already exists')){
        toast.error('This Email is already registered.', {
          position: "top-right",
          hideProgressBar: true,
          autoClose: 3000, 
          className: 'custom-toast',
          bodyClassName: 'custom-toast-body', 
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
      else if (data?.success === false && data?.message?.includes('UserName Already exists')) {
        toast.error('This Username is already taken.', {
          position: "top-right",
          hideProgressBar: true,
          autoClose: 3000, 
          className: 'custom-toast',
          bodyClassName: 'custom-toast-body', 
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    } catch (error) {
      console.log(error);
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const getCurrentUser = createAsyncThunk(
  "auth/getCurrentUser",
  async (_, { rejectWithValue }) => {
    try {
      // Fetch the current user
      const res = await API.get("/auth/current-user");
      if (res.data && res.data.success) {
        const user = res.data.user;
        // console.log("user:",user);
        const preferenceRes = await API.get(`/auth/preferences/${user.BusinessId}`);
        if (preferenceRes.data && preferenceRes.data.success) {
          //  console.log("preferenceRes.data:",preferenceRes.data);
          //  console.log("preferenceRes.data.userPreference.UserFilter:", preferenceRes.data.userPreference.UserFilter);
          const userPreferences = preferenceRes.data.userPreference;
          
            // console.log('userauthAction:',user)
            // console.log('userPreferences:',userPreferences)
          // Merge user data and user preferences into one object
          return { 
            ...user, 
            userFilter: userPreferences.UserFilter, 
            emailNotification: userPreferences.EmailNotification 
          };
        } else {
          return {  ...user, 
            userFilter: 'All', 
            emailNotification: 'On'
          }; // Default value if fetching preferences fails
        }
      }
    } catch (error) {
      console.log(error);
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);
