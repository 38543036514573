import React, {useRef, useState } from 'react';
import FormField from './FormField'; 
import '../../../Styles/OwnerDetailForm.css';
import {  toast } from 'react-toastify';
import { ToastConfig,SuccessToastConfig } from './ToastConfig';
import emailjs from '@emailjs/browser';
import { FaSpinner } from 'react-icons/fa'; // Spinner icon from react-icons

const ContactUsForm = () => {
  const [formData, setFormData] = useState({
    name: '',
    contactNumber: '',
    BusinessName: '',
    Description: '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

 
  const handleClear = () => {
    setFormData({
      name: '',
      contactNumber: '',
      BusinessName: '',
      Description: '',
    });
  };
  const [isSubmitting, setIsSubmitting] = useState(false); // State to track form submission
  const form = useRef(); // Ref for the form

  const handleContactInquiry = (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    emailjs.sendForm(
      'service_r7w8y7i', 
      'template_ccxtfcn', 
      form.current, 
      'AmeFlY_OkwGOolptI'
    )
    .then((result) => {
      // console.log(result.text);
      toast.success('Email sent successfully!', SuccessToastConfig);
      handleClear();
    })
    .catch((error) => {
      // console.log(error.text);
      toast.error('Email could not be sent!', ToastConfig);
    })
    .finally(() => {
      setIsSubmitting(false);
    });
  };

  return (
    <div className="EMS_Add_Event_Box box">
        <form onSubmit={handleContactInquiry} ref={form}>
        <h1 className="title">Contact Us</h1>
        <div className="field">
            <div className="control">
            <FormField
                label="Name"
                type="text"
                name="name"
                value={formData.name}
                onChange={handleChange}
                placeholder="Your Name"
                required={true}
            />
            </div>
        </div>

        <div className="field">
            <div className="control">
            <FormField
            label="Contact Number"
            type="text"
            name="contactNumber"
            value={formData.contactNumber}
            onChange={handleChange}
            placeholder="Your Contact Number"
            required={true}
            />
            </div>
        </div>
        
        <div className="field">
            <div className="control">
            <FormField
                label="Hall/Business Name"
                type="text"
                name="BusinessName"
                value={formData.BusinessName}
                onChange={handleChange}
                placeholder="Hall or Business Name"
                required={true}
            />
            </div>
        </div>

        <div className="field">
            <label className="EMS_Add_Event_Label">Contact Reason<span className="has-text-danger">*</span></label>
            <div className="control">
            <textarea
                className="textarea EMS_Add_Event_input"
                name="Description"
                value={formData.Description}
                onChange={handleChange}
                placeholder="We want to hear from you"
                required
            />
            </div>
        </div>

        <div className="field is-grouped">
            <div className="control">
            {/* <button type="submit" className="button btn-bg">Submit</button> */}
            <button 
            type="submit" 
            className="button btn-bg" 
            disabled={isSubmitting} // Disable button when submitting
          >
            {isSubmitting ? (
              <>
                 Sending...
              </>
            ) : (
              'Submit'
            )}
          </button>
            </div>
            <div className="control">
            <button type="button" className="button is-light" onClick={handleClear}>Clear</button>
            </div>
        </div>
        </form> 
    </div>
  );
};

export default ContactUsForm;
