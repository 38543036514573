import React,{useEffect} from 'react';
import tutorialVideo from '../../Tutorial/REC.mp4';  
import '../../Styles/EMSTutorial.css'; // Optional: if you want to add custom styles
import{ClearLocalStorageAtMidnight} from '../../Utils/MidnightClear'

const EMSTutorial = () => {
  
  useEffect(() => {
    ClearLocalStorageAtMidnight();
  },[]);
  return (
    <>
    <h1 className="title my-3">Tutorial</h1>
    <div className="video-container">
      <video className="video-player" controls>
        <source src={tutorialVideo} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
    </div>
    </>
  );
};

export default EMSTutorial;
