import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import 'bulma/css/bulma.css';
import '../../../Styles/EditModal.css';
import { FaChevronDown, FaChevronUp } from 'react-icons/fa'; 
const {
    formatTime,
    formatDate
  } = require('../../../Utils/DateTimeFormat');
    

const EventModal = ({ title, isOpen, onClose, events }) => {
    const [selectedEvent, setSelectedEvent] = useState(null);
    const [expandedEvent, setExpandedEvent] = useState(null);
    const navigate = useNavigate();
  
    const handleSelectEvent = (event) => {
      setSelectedEvent(event);
      setExpandedEvent(expandedEvent === event._id ? null : event._id);
    };
    const handleRowClick = (event) => {
        navigate(`/event-detail/${event._id}`, { state: { event } });
      };
    
    return (
      isOpen ? (
        <div className="modal-overlay">
          <div className="modal-content">
            <header className="modal-card-head" style={{ backgroundColor: "white" }}>
                <h2 className="modal-card-title" style={{ backgroundColor: "white", color: "black" }}>{title}</h2>
                <button className="delete" aria-label="close" onClick={onClose}></button>
            </header>
            
            {/* <div className="dropdown-container">
              {events.map((event) => (
                <div key={event.id} className="event-dropdown">
                  <button 
                    onClick={() => handleSelectEvent(event)} 
                    className="dropdown-btn"
                  >
                        {event.eventDetails.eventType === "Custom" 
                        ? event.eventDetails.customEventType 
                        : event.eventDetails.eventType} - {formatDate(event.eventDetails.eventDay)}
                    <span className={`icon-transition ${expandedEvent === event._id ? 'up' : ''}`}>
                      {expandedEvent === event.id ? <FaChevronUp /> : <FaChevronDown />}
                    </span>
                  </button>
                  <div className={`event-details ${expandedEvent === event._id ? 'expanded' : ''}`}>
                    <p><strong>Event Owner: </strong> {event.ownerDetails.ownerName}</p>
                    <p><strong>Event Type: </strong> 
                      {event.eventDetails.eventType === "Custom" 
                        ? event.eventDetails.customEventType 
                        : event.eventDetails.eventType}
                    </p>
                    <p><strong>Event Date: </strong>{formatDate(event.eventDetails.eventDay)}</p>
                    <p><strong>Time: </strong>{formatTime(event.eventDetails.eventTime)}</p>
                    <button key={event._id} onClick={() => handleRowClick(event)} className='showmoreLink'>Show More</button>
                  </div>
                </div>
              ))}
            </div> */}

            <div className="dropdown-container">
              {events.length === 0 ? (
                <div className="has-text-centered">
                  There are no {title}.
                </div>
              ) : (
                events.map((event) => (
                  <div key={event.id} className="event-dropdown">
                    <button 
                      onClick={() => handleSelectEvent(event)} 
                      className="dropdown-btn"
                    >
                      {event.eventDetails.eventType === "Custom" 
                        ? event.eventDetails.customEventType 
                        : event.eventDetails.eventType} - {formatDate(event.eventDetails.eventDay)}
                      <span className={`icon-transition ${expandedEvent === event._id ? 'up' : ''}`}>
                        {expandedEvent === event.id ? <FaChevronUp /> : <FaChevronDown />}
                      </span>
                    </button>
                    <div className={`event-details ${expandedEvent === event._id ? 'expanded' : ''}`}>
                      <p><strong>Event Owner: </strong> {event.ownerDetails.ownerName}</p>
                      <p><strong>Event Type: </strong> 
                        {event.eventDetails.eventType === "Custom" 
                          ? event.eventDetails.customEventType 
                          : event.eventDetails.eventType}
                      </p>
                      <p><strong>Event Date: </strong>{formatDate(event.eventDetails.eventDay)}</p>
                      <p><strong>Time: </strong>{formatTime(event.eventDetails.eventTime)}</p>
                      <button key={event._id} onClick={() => handleRowClick(event)} className='showmoreLink'>Show More</button>
                    </div>
                  </div>
                ))
              )}
            </div>

          </div>
        </div>
      ) : null
    );
  };
    

export default EventModal;
