import React,{useState} from 'react';
import { useParams, useNavigate ,useLocation} from 'react-router-dom';
import { FaArrowLeft, FaPrint, FaFilePdf } from 'react-icons/fa';
import '../../Styles/EventDetail.css';
import { jsPDF } from "jspdf";
import html2canvas from "html2canvas";
import{ClearLocalStorageAtMidnight} from '../../Utils/MidnightClear'
 
import { formatTime, formatDate } from '../../Utils/DateTimeFormat';


const EventDetail = () => {
  const { eventId } = useParams();
  const { state } = useLocation();
  const navigate = useNavigate();
  const [isPDFLoading, setIsPDFLoading] = useState(false);

  // Check if event data is available
  if (!state?.event) {
    return <div>No event data available</div>;
  }

  const { event } = state;
  // console.log('event:',event);
  
  
  const handlePrint = () => {
    window.print();
  };  
  const handleExportPDF = () => {
    setIsPDFLoading(true); // Start loading, show the spinner

    setTimeout(() => {

    const doc = new jsPDF();
    const eventName = `Event_Detail_${event.ownerDetails.ownerName}`;
  
    let yPosition = 20; // Starting y-position
    const lineHeight = 10;
    const pageHeight = doc.internal.pageSize.height;
    const pageWidth = doc.internal.pageSize.width;
    const margin = 14;
    const maxWidth = pageWidth - margin * 2;
    const paymentSpacing = 6; // Additional spacing after each payment block
  
    // Function to add text with page break check
    const addText = (text, x, y, options = {}) => {
      const lines = doc.splitTextToSize(text, maxWidth);
      lines.forEach((line) => {
        if (y > pageHeight - 20) {
          doc.addPage();
          y = 20; // Reset y-position for the new page
        }
        doc.text(line, x, y, options);
        y += lineHeight;
      });
      return y;
    };
  
    // Function to center the heading
    const addCenteredHeading = (text, y) => {
      doc.setFont('helvetica', 'bold');
      const textWidth = doc.getTextWidth(text);
      const xPosition = (pageWidth - textWidth) / 2;
      y = addText(text, xPosition, y);
      doc.setFont('helvetica', 'normal');
      return y;
    };
  
    // Add Title (if needed)
    doc.setFontSize(18);
    // yPosition = addCenteredHeading("Event Details", yPosition);
  
    // Owner Details Section
    doc.setFontSize(16);
    yPosition = addCenteredHeading("Event Owner Details", yPosition);
  
    // Full-width line
    doc.setLineWidth(0.5);
    doc.line(margin, yPosition, pageWidth - margin, yPosition);
    yPosition += lineHeight;
  
    doc.setFontSize(14);
    yPosition = addText(`Owner Name: ${event.ownerDetails.ownerName}`, margin, yPosition);
    yPosition = addText(`CNIC: ${event.ownerDetails.cnic}`, margin, yPosition);
    yPosition = addText(`Phone: ${event.ownerDetails.phone}`, margin, yPosition);
    if (event.ownerDetails.email) {
      yPosition = addText(`Email: ${event.ownerDetails.email}`, margin, yPosition);
    }
    if (event.ownerDetails.Description) {
      yPosition = addText(`Description: ${event.ownerDetails.Description}`, margin, yPosition);
    }
  
    // Event Details Section
    doc.setFontSize(16);
    yPosition = addCenteredHeading("Event Details", yPosition);
  
    // Full-width line
    doc.setLineWidth(0.5);
    doc.line(margin, yPosition, pageWidth - margin, yPosition);
    yPosition += lineHeight;
  
    doc.setFontSize(14);
    yPosition = addText(`Event Type: ${event.eventDetails.eventType === "Custom" ? event.eventDetails.customEventType : event.eventDetails.eventType}`, margin, yPosition);
    yPosition = addText(`Number of Persons: ${event.eventDetails.numOfPersons}`, margin, yPosition);
    if (event.eventDetails.ratePerGuest) {
      yPosition = addText(`Rate per Guest: ${event.eventDetails.ratePerGuest}`, margin, yPosition);
    }
    yPosition = addText(`Event Day: ${formatDate(event.eventDetails.eventDay)}`, margin, yPosition);
    yPosition = addText(`Event Time: ${formatTime(event.eventDetails.eventTime)}`, margin, yPosition);
    if (event.eventDetails.hallNo) {
      yPosition = addText(`Hall No: ${event.eventDetails.hallNo}`, margin, yPosition);
    }
    if (event.eventDetails.Description) {
      yPosition = addText(`Description: ${event.eventDetails.Description}`, margin, yPosition);
    }
  
    // Catering Details Section
    doc.setFontSize(16);
    yPosition = addCenteredHeading("Catering Details", yPosition);
  
    // Full-width line
    doc.setLineWidth(0.5);
    doc.line(margin, yPosition, pageWidth - margin, yPosition);
    yPosition += lineHeight;
  
    doc.setFontSize(14);
    yPosition = addText(`Number of Chairs: ${event.cateringDetails.numOfChairs}`, margin, yPosition);
    if (event.cateringDetails.numOfSofas) {
      yPosition = addText(`Number of Sofas: ${event.cateringDetails.numOfSofas}`, margin, yPosition);
    }
    if (event.cateringDetails.decorationTheme) {
      yPosition = addText(`Decoration Theme: ${event.cateringDetails.decorationTheme}`, margin, yPosition);
    }
  
    // Decor Items
    if (Object.values(event.cateringDetails.decorItems || {}).some(item => item.checked)) {
      yPosition = addText("Decor Items:", margin, yPosition);
      Object.entries(event.cateringDetails.decorItems).forEach(([key, value], idx) => {
        if (value.checked) {
          yPosition = addText(`${key.replace(/([A-Z])/g, ' $1').replace(/^./, str => str.toUpperCase())}: ${value.amount} PKR`, margin, yPosition);
        }
      });
    }
  
    // Food Details Section
    doc.setFontSize(16);
    yPosition = addCenteredHeading("Food Details", yPosition);
  
    // Full-width line
    doc.setLineWidth(0.5);
    doc.line(margin, yPosition, pageWidth - margin, yPosition);
    yPosition += lineHeight;
  
    yPosition = addText(`Number of Dishes: ${event.foodDetails.numDishes === "custom" ? event.foodDetails.customNumDishes : event.foodDetails.numDishes || 'None'}`, margin, yPosition);
  
    // Food Dishes
    if (event.foodDetails.dishes && event.foodDetails.dishes.length > 0) {
      yPosition = addText("Dishes:", margin, yPosition);
      event.foodDetails.dishes.forEach((dish, index) => {
        yPosition = addText(`Dish ${index + 1}: ${dish.name} (${dish.DishType})`, margin, yPosition);
      });
    } else {
      yPosition = addText("No dishes available", margin, yPosition);
    }
  
    if (event.foodDetails.Description) {
      yPosition = addText(`Description: ${event.foodDetails.Description}`, margin, yPosition);
    }
  
    // Payment Details Section
    doc.setFontSize(16);
    yPosition = addCenteredHeading("Payment Details", yPosition);
  
    // Full-width line
    doc.setLineWidth(0.5);
    doc.line(margin, yPosition, pageWidth - margin, yPosition);
    yPosition += lineHeight;
  
    if (event.paymentsDetails && Object.keys(event.paymentsDetails).length > 0) {
      Object.entries(event.paymentsDetails).forEach(([key, payment], idx) => {
        if (payment.amount) {
          doc.setFont('helvetica', 'bold');
          yPosition = addText(`${key.replace(/([A-Z])/g, ' $1').replace(/^./, str => str.toUpperCase())} Payment:`, margin, yPosition);
    
          doc.setFont('helvetica', 'normal');
          yPosition = addText(`Amount: ${payment.amount} PKR`, margin, yPosition);
          yPosition = addText(`Receiving Date: ${formatDate(payment.dateReceived)}`, margin, yPosition);
          yPosition = addText(`Received By: ${payment.receivedBy}`, margin, yPosition);
          yPosition += paymentSpacing; // Add extra space after each payment
        }
      });
    }
    // Save the PDF
    doc.save(`${eventName}.pdf`);
    
    setIsPDFLoading(false); // Stop loading after saving the PDF
    }, 2000);

  };
  
  return (
    <div  className={`event-detail-container 
      ${event.eventDetails.eventStatus === 'Active' ? 'status-active' : ''} 
      ${event.eventDetails.eventStatus === 'Done' ? 'status-done' : ''}
    `}>
      <div className="event-detail-box">
      <div className="button-group is-flex is-align-items-center is-justify-content-space-between">
        <button className="back-arrow" onClick={() => navigate(-1)}>
          <FaArrowLeft />
        </button>

        <div className='exportButtons'>
          <button className="print-btn button is-small export-btn" onClick={handlePrint}>
            <FaPrint className='printSVG' style={{ marginRight: '1vh',fontSize:'3vh',color:'#007BFF' }}/> Print
          </button>
          {/* <button className="pdf-btn button is-small export-btn" onClick={handleExportPDF}>
            <FaFilePdf className='pdfSVG' style={{ marginRight: '1vh',fontSize:'3vh',color:'#b11c20' }}/> Export PDF
          </button> */}
           <button className="button is-small export-btn has-background-white " onClick={handleExportPDF} disabled={isPDFLoading}>
            {isPDFLoading ? (
              <span className="spinner-pdf"></span> // Custom spinner
            ) : (
              <FaFilePdf className="pdfSVG" style={{ marginRight: '1vh', fontSize: '3vh', color: '#b11c20' }} />
            )}
            {isPDFLoading ? '' : 'Export PDF'}
          </button>
        </div>

      </div>
      
        {/* Owner Details */}
        <div className="event-section">
        <h3 className="title">Event Owner Details</h3>
        <hr />
          {event.ownerDetails && (
            <div>
              <div className="EventDetail_HeadingInline">
              <span><strong>Owner Name:</strong> {event.ownerDetails.ownerName}</span>
              <span><strong>CNIC:</strong> {event.ownerDetails.cnic}</span>
              </div>
              
              <div className="EventDetail_HeadingInline">
              <span><strong>Phone:</strong> {event.ownerDetails.phone}</span>          
              {event.ownerDetails.email && (
                <span><strong>Email:</strong> {event.ownerDetails.email}</span>
              )}
              </div>
              
              {event.ownerDetails.Description && (
                <div className="EventDetail_HeadingInline">
                  <p className='EventDescription'><strong>Description:</strong> {event.ownerDetails.Description}</p>
                </div>
              )}
            </div>
          )}
        </div>

        {/* Event Details */}
        <div className="event-section">
          <h3 className="title">Event Details</h3>
          <hr />
          {event.eventDetails && (
            <div>
              <div className="EventDetail_HeadingInline">
                <span><strong>Event Type:</strong> 
                  {event.eventDetails.eventType === "Custom" 
                    ? event.eventDetails.customEventType 
                    : event.eventDetails.eventType}
                </span>
                <span><strong>Number of Persons:</strong> {event.eventDetails.numOfPersons}</span>
              </div>
                
            <div className="EventDetail_HeadingInline">
            {event.eventDetails.ratePerGuest && (
              <span><strong>Rate per Guest:</strong> {event.eventDetails.ratePerGuest}</span>
            )}
              <span><strong>Event Day:</strong> {formatDate(event.eventDetails.eventDay)}</span>
            </div>
        
            <div className="EventDetail_HeadingInline">
              <span><strong>Event Time:</strong> {formatTime(event.eventDetails.eventTime)}</span>
              {event.eventDetails.hallNo && (
                <span><strong>Hall No:</strong> {event.eventDetails.hallNo}</span>
              )}
            </div>
            <div className="EventDetail_HeadingInline">
              {event.eventDetails.Description && (
                <span><strong>Description:</strong> {event.eventDetails.Description}</span>
              )}
            </div>

          </div>
          )}
        </div>

        {/* Catering Details */}
        <div className="event-section">
          <h3  className="title">Catering Details</h3>
          <hr />
          {event.cateringDetails && (
            <>
              <div className="EventDetail_HeadingInline">
                <span><strong>Number of Chairs:</strong> {event.cateringDetails.numOfChairs}</span>
                
                  {event.cateringDetails.numOfSofas != null && !isNaN(event.cateringDetails.numOfSofas) && (
                    <span>
                      <strong>Number of Sofas:</strong> {event.cateringDetails.numOfSofas}
                    </span>
                  )}
              </div>

              <div className="EventDetail_HeadingInline">
                {event.cateringDetails.decorationTheme && (
                  <span><strong>Decoration Theme:</strong> {event.cateringDetails.decorationTheme}</span>
                )}
              </div>

              <div className='has-text-centered'>
                {Object.values(event.cateringDetails.decorItems || {}).some(item => item.checked) ? (
                  <>
                    <strong>Decor Items</strong>
                    <div className="EventDetail_DecorItems">
                      {Object.entries(event.cateringDetails.decorItems || {}).map(([key, value]) => (
                        value.checked && (
                          <div key={key} className="EventDetail_HeadingInline">
                            <span>
                              <strong>
                                {key.replace(/([A-Z])/g, ' $1').replace(/^./, str => str.toUpperCase())}:
                              </strong>
                              <span> {value.amount} PKR</span>
                            </span>
                          </div>
                        )
                      ))}
                    </div>
                  </>
                ) : (
                  <p>No decor items available</p>
                )}
              </div>
           </>
          )}
        </div>

        {/* Food Details */}
        <div className="event-section">
          <h3 className="title">Food Details</h3>
          <hr />
          {event.foodDetails && (
            <div className='has-text-centered'>
              <div className="EventDetail_HeadingInline">
                <span><strong>Number of Dishes: </strong> 
                  {event.foodDetails.numDishes === "custom" 
                    ? event.foodDetails.customNumDishes 
                    : event.foodDetails.numDishes || 'None'}
                </span>
              </div>

              {event.foodDetails.dishes && event.foodDetails.dishes.length > 0 ? (
                <>
                  <strong>Dishes</strong>
                  <div className="EventDetail_Dishes">
                    {event.foodDetails.dishes.map((dish, index) => (
                      <div key={index} className="EventDetail_HeadingInline">
                        <span><strong>Dish {index + 1}:</strong> {dish.name}<span>({dish.DishType})</span></span>
                      </div>
                    ))}
                  </div>
                </>
              ) : (
                <p>No dishes available</p>
              )}

                {/* Display Drinks */}
                {event.foodDetails.drinks && Object.keys(event.foodDetails.drinks).some(key => event.foodDetails.drinks[key].checked) && (
                  <>
                    <strong>Drinks</strong>
                    <div className="EventDetail_Drinks">
                      {Object.entries(event.foodDetails.drinks).map(([drinkName, drinkDetails], index) => (
                        drinkDetails.checked && (
                          <div key={index} className="EventDetail_HeadingInline">
                            <span>
                              <strong>{drinkName}: </strong> 
                              {drinkDetails.quantity || 'N/A'}
                            </span>
                          </div>
                        )
                      ))}
                    </div>
                  </>
                )}

                    {/* Display Tea */}
                    {event.foodDetails.Tea && Object.keys(event.foodDetails.Tea).some(key => event.foodDetails.Tea[key].checked) && (
                      <>
                        <strong>Tea</strong>
                        <div className="EventDetail_Tea">
                          {Object.entries(event.foodDetails.Tea).map(([teaName, teaDetails], index) => (
                            teaDetails.checked && (
                              <div key={index} className="EventDetail_HeadingInline">
                                <span>
                                  <strong>{teaName}: </strong> 
                                  {teaDetails.quantity || 'N/A'}
                                </span>
                              </div>
                            )
                          ))}
                        </div>
                      </>
                    )}
            </div>
          )}
                {event.foodDetails.Description && (
                  <div className="EventDetail_HeadingInline">
                    <span><strong>Description:</strong> {event.foodDetails.Description}</span>
                  </div>
                )}
        </div>

        {/* Payment Details */}
        <div className="event-section">
          <h3 className="title">Payments</h3>
          <h5 className="subtitle is-5">Total Charges: {event.totalCost.toLocaleString()} PKR</h5>
          <hr />
          {event.paymentsDetails && (
            Object.entries(event.paymentsDetails).map(([key, payment], index) => (
              payment.amount && (
                <div key={index}>
                  <strong>{key.replace(/([A-Z])/g, ' $1').replace(/^./, str => str.toUpperCase())} Payment:</strong> 
                  
                  <div className='PaymentSection'>
                    <span>
                    Amount: {payment.amount} 
                    </span>
                    <span>
                    Receiving Date: {formatDate(payment.dateReceived)}
                    </span>
                    <span>
                     Received By: {payment.receivedBy}
                    </span>
                  </div>
                </div>
              )
            ))
          )}
        </div>
      </div>
    </div>
  );
};



export default EventDetail;
