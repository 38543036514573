import React, { useState, useEffect } from 'react';
import 'bulma/css/bulma.css';
import '../../../Styles/CateringDetailForm.css';
import FormField from './FormField';
import {  toast } from 'react-toastify';
import { ToastConfig } from '../../shared/Forms/ToastConfig';


const CateringDetailForm = ({ onNext,onEdit, onSave, onClear, formData: initialFormData, mode, NumOfGuest }) => {
  const [formData, setFormData] = useState({
    numOfChairs: 0,
    numOfSofas: '',
    decorationTheme: '',
    decorItems: {
      mainStageFlower: { checked: false, amount: '' },
      dj: { checked: false, amount: '' },
      followSpotlight: { checked: false, amount: '' },
      coolFireAnar: { checked: false, amount: '' },
      trussUChokta: { checked: false, amount: '' },
      danceFloor: { checked: false, amount: '' },
      seeThroughFloors: { checked: false, amount: '' },
      glassFloorWalkway: { checked: false, amount: '' },
      panelPartition: { checked: false, amount: '' },
      heater: { checked: false, amount: '' },
    },
    DecorCost:0,
  });
  useEffect(() => {
    setFormData((prevData) => ({
      ...prevData,
      numOfChairs: NumOfGuest || 0, 
    }));
  }, [NumOfGuest]);

  useEffect(() => {
    if (initialFormData) {
      setFormData(prevFormData => ({
        ...prevFormData,
        ...initialFormData,
        // Ensure that all decorItems are initialized
        decorItems: {
          ...prevFormData.decorItems,
          ...initialFormData.decorItems, // Merge existing items with new data
          // Fill in missing items with default values
          mainStageFlower: initialFormData.decorItems?.mainStageFlower || { checked: false, amount: '' },
          dj: initialFormData.decorItems?.dj || { checked: false, amount: '' },
          followSpotlight: initialFormData.decorItems?.followSpotlight || { checked: false, amount: '' },
          coolFireAnar: initialFormData.decorItems?.coolFireAnar || { checked: false, amount: '' },
          trussUChokta: initialFormData.decorItems?.trussUChokta || { checked: false, amount: '' },
          danceFloor: initialFormData.decorItems?.danceFloor || { checked: false, amount: '' },
          seeThroughFloors: initialFormData.decorItems?.seeThroughFloors || { checked: false, amount: '' },
          glassFloorWalkway: initialFormData.decorItems?.glassFloorWalkway || { checked: false, amount: '' },
          panelPartition: initialFormData.decorItems?.panelPartition || { checked: false, amount: '' },
          heater: initialFormData.decorItems?.heater || { checked: false, amount: '' },
        }
      }));
    }
  }, [initialFormData]);
  
  // const handleChange = (event) => {
  //   const { name, value, type, checked } = event.target;
  // // Check for negative values for input types that should not accept negative values
  // if (type === 'number' && value < 0) {
  //   // alert('Value cannot be negative');
  //   toast.error("Value cannot be negative", ToastConfig);
  //   return;
  // }
  //   if (type === 'checkbox') {
  //     setFormData((prevFormData) => ({
  //       ...prevFormData,
  //       decorItems: {
  //         ...prevFormData.decorItems,
  //         [name]: {
  //           ...prevFormData.decorItems[name],
  //           checked: checked,
  //         },
  //       },
  //     }));
  //     onEdit();
  //   } else {
  //     setFormData((prevFormData) => ({
  //       ...prevFormData,
  //       [name]: value,
  //     }));
  //     onEdit();
  //   }
  // };

  const handleChange = (event) => {
    const { name, value, type, checked } = event.target;
  
    // Handle number inputs
    if (type === 'number') {
      const numericValue = parseFloat(value); // Convert value to a number
  
      // Check for negative values
      if (numericValue <= 0) {
        toast.error("Value cannot be negative or zero", ToastConfig);
        return;
      }
  
      // Update formData for numeric inputs
      setFormData((prevFormData) => ({
        ...prevFormData,
        [name]: numericValue,
      }));
    } else if (type === 'checkbox') {
      // Update formData for checkbox inputs
      setFormData((prevFormData) => ({
        ...prevFormData,
        decorItems: {
          ...prevFormData.decorItems,
          [name]: {
            ...(prevFormData.decorItems[name] || {}),
            checked: checked,
          },
        },
      }));
    } else {
      // Update formData for other inputs
      setFormData((prevFormData) => ({
        ...prevFormData,
        [name]: value,
      }));
    }
    if (onEdit) onEdit();
    // onEdit();
  };
  
  const handleAmountChange = (event) => {
    const { name, value } = event.target;

    const numericValue = parseFloat(value); // Convert value to a number
  
      // Check for negative values
      if (numericValue <= 0) {
        toast.error("Amount cannot be negative or zero", ToastConfig);
        return;
      }

    setFormData((prevFormData) => ({
      ...prevFormData,
      decorItems: {
        ...prevFormData.decorItems,
        [name]: {
          ...prevFormData.decorItems[name],
          amount: value,
        },
      },
    }));
    // onEdit();
    if (onEdit) onEdit();
  };
  
  useEffect(() => {
    // Calculate the total decor cost
    const totalDecorCost = Object.values(formData.decorItems).reduce((total, item) => {
      return total + (item.checked && item.amount ? parseFloat(item.amount) : 0);
    }, 0);
  
    // Update the formData with the computed decor cost
    setFormData((prevData) => ({
      ...prevData,
      DecorCost: totalDecorCost
    }));
  }, [formData.decorItems]);
  
  const handleClear = () => {
    setFormData({
      numOfChairs: '',
      numOfSofas: '',
      decorationTheme: '',
      decorItems: {
        mainStageFlower: { checked: false, amount: '' },
        dj: { checked: false, amount: '' },
        followSpotlight: { checked: false, amount: '' },
        coolFireAnar: { checked: false, amount: '' },
        trussUChokta: { checked: false, amount: '' },
        danceFloor: { checked: false, amount: '' },
        seeThroughFloors: { checked: false, amount: '' },
        glassFloorWalkway: { checked: false, amount: '' },
        panelPartition: { checked: false, amount: '' },
        heater: { checked: false, amount: '' },
      },
    });
    onClear();
  };

 
  const [isSubmitting, setIsSubmitting] = useState(false); // State to track submission

  const handleSubmit = async (e) => {
    e.preventDefault();
    const { numOfChairs } = formData;

    if (numOfChairs) {
      setIsSubmitting(true); // Disable the button during submission
      try {
        if (mode === 'add') {
          onNext(formData); // Await the next action
        } else {
          await onSave(formData); // Await the save action
        }
      } catch (error) {
        console.log('Error:', error);
      } finally {
        setIsSubmitting(false); // Re-enable the button after submission
      }
    } else {
      console.log('Please fill all required fields');
    }
  };

  return (
    <div className="container" style={{ marginTop: "8%" }}>
      <div className="EMS_Add_Event_Box box">
        <h1 className="title">Catering Details</h1>
        <form onSubmit={handleSubmit}>
          <div className="columns ">
            <FormField
              label="Number of Chairs"
              type="number"
              name="numOfChairs"
              value={formData.numOfChairs}
              onChange={handleChange}
              required={true}
            />
            
            <FormField
              label="Number of Sofas"
              type="number"
              name="numOfSofas"
              value={formData.numOfSofas}
              onChange={handleChange}
              // required={true}
            />
          </div>
            <p className="cost-display" style={{ color: '#888', fontSize: '0.9em',marginBottom:'1em' }}>
            You have total: {NumOfGuest} guests
            </p>
          <div className="field">
            <FormField
              label="Hall Decoration Theme"
              type="text"
              name="decorationTheme"
              value={formData.decorationTheme}
              onChange={handleChange}
              placeholder="Describe the decoration theme"
              isTextarea={true}
              required={false} 
            />
          </div>
          {/* Decor Items */}
          <hr />
            <h3 className="title is-4" style={{ marginBottom: '1rem' }}>
              Decor Items
            </h3>

            <div className="columns is-multiline" style={{ alignItems: 'start' }}>
            {Object.entries(formData.decorItems).map(([key, item], index) => (
              <div key={key} className="column is-half" style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
                <label className='DecorLabel'>
                  <input
                    type="checkbox"
                    name={key}
                    checked={item.checked}
                    onChange={handleChange}
                  />
                   {`${key
                      .split(/(?=[A-Z])/)
                      .join(' ') 
                      .replace(/^\w/, (c) => c.toUpperCase())}${key === 'heater' ? '/AC' : ''}`}
                </label>
                {item.checked && (
                  <input
                    type="number"
                    name={key}
                    value={item.amount}
                    onChange={handleAmountChange}
                    placeholder="Amount"
                    className="input EMS_Add_Event_input"
                    required={true}
                      
                    style={{ marginLeft: '10px', width: '60%' }}
                  />
                )}
              </div>
            ))}
            </div>

            {/* Total Decor Amount */}
            <hr />
            <div className="field">
              <label className="EMS_Add_Event_Label" style={{ fontWeight: 'bold', marginRight: '10px' }}>
                Total Decor Amount:
              </label>
              <span style={{ fontWeight: 'bold', fontSize: '1.2em' }}>
                  PKR {(formData.DecorCost ?? 0).toFixed(2)}
              </span>
            </div>


            
          <div className="field is-grouped">
            <div className="control">
              <button type="button" className="button is-light" onClick={handleClear}>Clear Fields</button>
            </div>
            <div className="control">
              {/* <button type="submit" className="button btn-bg">{mode === 'add' ? 'Next' : 'Save'}</button> */}
              <button
                type="submit"
                className="button btn-bg"
                disabled={isSubmitting} // Disable the button during submission
              >
                {isSubmitting ? 'Saving...' : (mode === 'add' ? 'Next' : 'Save')}
              </button>

            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default CateringDetailForm;
