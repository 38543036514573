const ClearLocalStorageAtMidnight = () => {
    const clearLocalStorageAtMidnight = () => {
      // localStorage.clear();
      console.log("LocalStorage cleared at midnight");
    };

    const now = new Date();
    const midnight = new Date();
    midnight.setHours(24, 0, 0, 0);
    // midnight.setHours(0, 12, 0, 0);

    const timeUntilMidnight = midnight.getTime() - now.getTime();
    console.log("timeUntilMidnight:",timeUntilMidnight)
    
    const timer = setTimeout(clearLocalStorageAtMidnight, timeUntilMidnight);

    return () => clearTimeout(timer);
};

// module.exports={ClearLocalStorageAtMidnight}
export { ClearLocalStorageAtMidnight };
