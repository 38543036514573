import "./App.css";
import SideBar from "./components/shared/Layout/SideBar";
import { BrowserRouter as Router, Route, Routes, useLocation } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Dashboard from "./pages/Admin/Dashboard";
import OwnerDetailForm from "./components/shared/Forms/OwnerDetailForm";
import FormsContainer from "./pages/Admin/FormsContainer";
import Login from "./pages/Auth/Login";
import ContactUsForm from "./components/shared/Forms/ContactUsForm";
import Notifications from "./pages/Admin/Notifications";
import EMSTutorial from "./pages/Admin/EMSTutorial";
import AllEvents from "./pages/Admin/AllEvents";
import EventDetail from "./pages/Admin/EventDetail";
import EditProfile from "./pages/Admin/EditProfile";
import ChangePassword from "./pages/Admin/ChangePassword";
import ProtectedRoute from "./components/Routes/ProtectedRoute";
import 'react-toastify/dist/ReactToastify.css';
import EventBanner from "./pages/Admin/EventBanner";


const AppRoutes = () => {
  const location = useLocation();
  const isAuthRoute = location.pathname === "/login";

  return (
    <>
      <ToastContainer />
      {isAuthRoute ? (
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="*" element={<>not found</>} />
        </Routes>
      ) : (
        <SideBar>
          <Routes>
            <Route path="/" element={
              <ProtectedRoute>
                  <Dashboard />
              </ProtectedRoute>
              } 
            />
            

            <Route path="/event_forms" element={
              <ProtectedRoute>
                 <FormsContainer />
              </ProtectedRoute>
              } 
            />
            <Route path="/banner" element={
              <ProtectedRoute>
                 <EventBanner />
              </ProtectedRoute>
              } 
            />
            <Route path="/contact_us" element={
              <ProtectedRoute>
                  <ContactUsForm/>
              </ProtectedRoute>
              }
            />
            <Route path="/notifications" element={
              <ProtectedRoute>
                  <Notifications/>
              </ProtectedRoute>
              }
            />
            <Route path="/tutorial" element={
              <ProtectedRoute>
                  <EMSTutorial/>
              </ProtectedRoute>
              }
            />
            <Route path="/change_password" element={
              <ProtectedRoute>
                  <ChangePassword/>
              </ProtectedRoute>
              }
            />
            <Route path="/edit_profile" element={
              <ProtectedRoute>
                  <EditProfile/>
              </ProtectedRoute>
              } 
            />

            <Route path="/events_all" element={
              <ProtectedRoute>
                  <AllEvents/>
              </ProtectedRoute>
              }
            />
            <Route path="/event-detail/:eventId" element={
              <ProtectedRoute>
                  <EventDetail />
              </ProtectedRoute>
              }
            />
            <Route path="*" element={<>not found</>} />
          </Routes>
        </SideBar>
      )}
    </>
  );
};

function App() {
  return (
    <Router>
      <AppRoutes />
    </Router>
  );
}
// function App() {
//   const location = useLocation();
//   const isAuthRoute = location.pathname === "/login";
//   console.log('location:',location)
//   console.log('isAuthRoute:',isAuthRoute)

//   return (
//     <>
   
//     {/* <Router>
//       <SideBar>
//         <Routes>
//           <Route path="/" element={<Dashboard />} />

//           Forms route
//           <Route path="/event_forms" element={<FormsContainer/>} />
//           <Route path="*" element={<> not found</>} />
//         </Routes>
//       </SideBar>
//     </Router> */}
//       <Router>
//         {isAuthRoute ? (
//           <Routes>
//             <Route path="/login" element={<Login />} />
//             <Route path="*" element={<>not found</>} />
//           </Routes>
//         ) : (
//           <SideBar>
//             <Routes>
//               <Route path="/" element={<Dashboard />} />
//               {/* Forms route */}
//               <Route path="/event_forms" element={<FormsContainer />} />
//               {/* Other routes */}
//               <Route path="*" element={<>not found</>} />
//             </Routes>
//           </SideBar>
//         )}
//       </Router>

//    </>
   
//   );
// }


export default App;
