// toastConfig.js
export const ToastConfig = {
    position: "top-right",
    hideProgressBar: true,
    autoClose: 2000,
    className: 'custom-toast',
    bodyClassName: 'custom-toast-body',
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined
  };
  
  export const SuccessToastConfig = {
    ...ToastConfig,
    className: 'custom-toast-success', 
    autoClose: 2000, 
  };

  export const InfoToastConfig = {
    ...ToastConfig,
    className: 'custom-toast-info',
    autoClose: 2000,
  };
  