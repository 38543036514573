// BarChart.js
import React from 'react';
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const BarChart = ({ dataPoints }) => {
  const data = {
    labels: dataPoints.labels,
    datasets: [
      {
        label: 'Completed Events',
        data: dataPoints.data,
        backgroundColor: 'rgba(255, 99, 132, 0.2)',  // Reddish background color
        borderColor: 'rgba(255, 99, 132, 1)',
        borderWidth: 1,
      }
    ]
  };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
      },
    //   title: {
    //     display: true,
    //     text: 'Completed Events by Month (Bar Chart)',
    //   },
    },
    scales: {
      x: {
        title: {
          display: true,
          text: 'Month',
        },
      },
      y: {
        title: {
          display: true,
          text: 'Completed Events',
        },
        beginAtZero: true,
        min: 0,  // Set minimum value to 0
        max: 5,  // Set maximum value to 5
        ticks: {
          stepSize: 1, // Set step size to 1 to ensure only whole numbers are displayed
        }
      },
    },
  };

  return <Bar data={data} options={options} />;
};

export default BarChart;