import API from "../services/API";

export const fetchEvents = async (username) => {
    try {
      const response = await API.get(`/eventForms/getAllEvents/${username}`);
      if (response.data.success) {
        return response.data.events;
      }
    } catch (error) {
      console.error('Error fetching events:', error);
    }
  };
  
  export const fetchAndSetEvents = async (username, setEvents, setLoading) => {
    const cacheName = 'my-event-cache';
    const cacheKey = `events-${username}-All`; // Key to identify cache
  
    try {
      const cache = await caches.open(cacheName);
      const cachedResponse = await cache.match(cacheKey);
      
      if (cachedResponse) {
        const cachedData = await cachedResponse.json();
        setEvents(cachedData);
      } else {
        const fetchedEvents = await fetchEvents(username);
        // If events are fetched successfully, sort them
        if (fetchedEvents && fetchedEvents.length > 0) {
          // Separate events into "Active" and "Done"
          const activeEvents = fetchedEvents.filter(event => event.eventDetails.eventStatus === 'Active');
          const doneEvents = fetchedEvents.filter(event => event.eventDetails.eventStatus === 'Done');
          
          // Sort each category by event day (most recent first)
          const sortedActiveEvents = activeEvents.sort((a, b) => new Date(a.eventDetails.eventDay) - new Date(b.eventDetails.eventDay));
          const sortedDoneEvents = doneEvents.sort((a, b) => new Date(a.eventDetails.eventDay) - new Date(b.eventDetails.eventDay));
          
          // Combine the sorted arrays with "Active" events first
          const sortedEvents = [...sortedActiveEvents, ...sortedDoneEvents];
          
          // Cache the sorted events and update state
          const response = new Response(JSON.stringify(sortedEvents)); 
          cache.put(cacheKey, response); 
          setEvents(sortedEvents);
        }
      }
    } catch (error) {
      console.error('Error fetching and setting events:', error);
    } finally {
      setLoading(false);
    }
  };