import React from 'react';
import '../../../Styles/OwnerDetailForm.css';

const FormField = ({ label, type, name, value, placeholder, isTextarea , onChange,required}) => {
  return (
    <div className={`column ${isTextarea ? 'p-0' : ''}`}>
      <div className="field">
      <label className="EMS_Add_Event_Label">
          {label}
          {required ? (
            <span className="has-text-danger">*</span> 
          ) : (
            <span className="optional-text" style={{ color: '#888', fontSize: '0.8em', marginLeft: '5px' }}>
              (Optional)
            </span>
          )}

        </label>
        <div className="control">
        {isTextarea ? (
          <textarea
            className="textarea EMS_Add_Event_Textarea"
            name={name}
            value={value}
            onChange={onChange}
            placeholder={placeholder ? placeholder : ''}
            required={required}
          />
        ) : (
          <input
            className="EMS_Add_Event_input input"
            type={type}
            name={name}
            value={value} 
            placeholder={placeholder ? placeholder : ''}
            onChange={onChange}
            required={required}
          />
        )}
        </div>
      </div>
    </div>
  );
};

export default FormField;
