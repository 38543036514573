import React ,{useRef, useState,useEffect }from 'react'
import '../../../Styles/ResetPwPopup.css';
import axios from 'axios';
// import { useNavigate } from 'react-router-dom/dist/umd/react-router-dom.development';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import OTPModal from './OTPModal';
import 'bulma/css/bulma.css';
import API from "../../../services/API";
import { ToastConfig,SuccessToastConfig } from '../../../components/shared/Forms/ToastConfig';
import CryptoJS from 'crypto-js'; 

const ForgotPwPopup = ({isOpen, onClose,  onOTPGenerated }) => {
    const [Email, setEmail] = useState(''); 
    const [OTP, setOTP]= useState(''); 
    const [isButtonDisabled, setIsButtonDisabled] = useState(false);

    const handleEmailChange = (event) => {
      setEmail(event.target.value); // Update UserEmail state with the input value
    };

    const form = useRef();

    const handleForgotPasswordInquiry = async (e) => {
      e.preventDefault();
      setIsButtonDisabled(true);
      try {
        // console.log('handleForgotPasswordInquiry...')
        const response = await API.post('/emailProcedures/verifyEmail', { email: Email });
        // console.log('response:',response)
        
        // Extract email from response
        const email = response.data.email;
        // console.log('email:',email)

        if (email) {
          // User exists, generate OTP
          const otp = Math.floor(1000 + Math.random() * 9000);
          setOTP(otp);

          const secretKey = process.env.REACT_APP_OTP_SECRET_KEY; // Secret key stored in .env file
          const encryptedOtp = CryptoJS.AES.encrypt(otp.toString(), secretKey).toString();
        
          // Prepare data to send OTP
          const dataSend = {
            email: email,
            otp: encryptedOtp,
          };
          const sendEmailResponse = await API.post('/emailProcedures/sendEmail', dataSend);
          // // Check if sending email was successful
          if (sendEmailResponse.status >= 200 && sendEmailResponse.status < 300) {
            // Notify parent component to show OTP modal
            onOTPGenerated(otp, email);
            // console.log('otp, email:',otp, email)
            
            // Notify user of successful OTP send
            toast.success('OTP sent to email',SuccessToastConfig);
          } else {
            // Handle unsuccessful email sending
            toast.error('Failed to send OTP. Please try again later.',ToastConfig);
          }
        } else {
          // Handle case where email is not found
          toast.error('User not found',ToastConfig);
        }
      } catch (error) {
        toast.error('An error occurred while processing the request', ToastConfig);
      }
      finally {
        setIsButtonDisabled(false); // Enable the button again after process completes
      }
    };
    return (
      <>
        <form onSubmit={handleForgotPasswordInquiry} className={`modal ${isOpen ? 'is-active' : ''}`}>
          <div className="modal-background"></div>
          <div className="modal-card">
            <header className="modal-card-head">
              <p className="modal-card-title">Forgot Password</p>
              <button className="delete" aria-label="close" onClick={onClose}></button>
            </header>
            <section className="modal-card-body">
              <p style={{ textAlign: 'center' }}>
                Enter the email address associated with your account, and we'll email you an OTP.
              </p>
              <input
                type="email"
                value={Email}
                required={true}
                onChange={handleEmailChange}
                className='input EMS_Add_Event_input'
                placeholder="Enter your Email"
                style={{ textAlign: 'center', margin: '10px auto' }}
              />
            </section>
            <footer className="modal-card-foot is-justify-content-center has-background-white">
            <button
              // onClick={handleForgotPasswordInquiry}
              type='submit'
              className='button btn-bg'
              style={{ width: '40%' }}
              disabled={isButtonDisabled} 
            >
              {isButtonDisabled ? 'Sending...' : 'SEND OTP'}
            </button>

            </footer>
          </div>
        </form>
      </>
    );
};
export default ForgotPwPopup