import { userLogin,userLoginViaEmail, userRegister } from "../redux/features/auth/authActions";
import store from "../redux/store";

// export const handleLogin = (e, loginFormData) => {
//   e.preventDefault();
//   const { UserName, password } = loginFormData;

//   try {
//     if (!UserName || !password) {
//       return alert("Please Provide All Feilds");
//     }
//     store.dispatch(userLogin({ UserName, password}));
//   } catch (error) {
//     console.log(error);
//   }
// };

export const handleLogin = async (e, loginFormData) => {
  e.preventDefault();
  const { UserName, password } = loginFormData;

  try {
    if (!UserName || !password) {
      alert("Please Provide All Fields");
      return;
    }
    return store.dispatch(userLogin({ UserName, password }));
  } catch (error) {
    console.log(error);
  }
};

export const handleLoginViaEmail = (e, Email) => {
  e.preventDefault();
  try {
    if (!Email) {
      return alert("Please Provide All Feilds");
    }
    store.dispatch(userLoginViaEmail({ Email}));
  } catch (error) {
    console.log(error);
  }
};
export const handleRegister =async  (e, registerFormData) => {
  e.preventDefault();
  const { BusinessName, UserName, email, location, phone, password } = registerFormData;
  // console.log("registerFormData:",registerFormData)

  try {
  return  store.dispatch(
      userRegister({
        BusinessName,
        UserName,
        email,
        location,
        phone,
        password
      })
    );
  } catch (error) {
    console.log(error);
  }
};
