import React, { useEffect, useState } from 'react';
import '../../Styles/Login.css';
import { Link } from 'react-router-dom';
import FormField from '../../components/shared/Forms/FormField';
import Register from './Register';
import img1 from '../../Assets/first.jpg';
import img2 from '../../Assets/second.jpg';
import img3 from '../../Assets/third.jpg';
import logo from '../../Assets/logo.png';
import {  handleLogin } from "../../services/authService";
import {  toast } from 'react-toastify';
import ForgotPwPopup from './ForgotPassword/ForgotPwPopup';
import OTPModal from './ForgotPassword/OTPModal';

const images = [img1, img2, img3];

const Login = () => {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [isRegistering, setIsRegistering] = useState(false);
  const [isForgotPwDialogOpen, setForgotPwDialogOpen] = useState(false);
  const [isOTPModalOpen, setOTPModalOpen] = useState(false);
  const [otp, setOtp] = useState('');
  const [email, setEmail] = useState('');

  // useEffect(() => {
  //   const interval = setInterval(() => {
  //     setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
  //   }, 3000);
  //   return () => clearInterval(interval);
  // }, []);

  const [loginFormData, setLoginFormData] = useState({
    UserName: '',
    password: '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setLoginFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const switchToRegister = () => {
    setIsRegistering(true);
  };

  const switchToLogin = () => {
    setIsRegistering(false);
  };


  // const handleSubmit = (e) => {
  //   // e.preventDefault();
  //   try {
  //     handleLogin(e,loginFormData);
  //   } catch (error) {
  //       toast.error('Login failed. Please try again.', {
  //         position: "top-right",
  //         hideProgressBar: true,
  //         autoClose: 3000, 
  //         className: 'custom-toast',
  //         bodyClassName: 'custom-toast-body', 
  //         closeOnClick: true,
  //         pauseOnHover: true,
  //         draggable: true,
  //         progress: undefined,
  //       }); 
  //   }
  // };

  const [loading, setLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true); 
  
    try {
      await handleLogin(e, loginFormData); 
    } catch (error) {
      toast.error('Login failed. Please try again.', {
        position: "top-right",
        hideProgressBar: true,
        autoClose: 3000, 
        className: 'custom-toast',
        bodyClassName: 'custom-toast-body', 
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } finally {
      setLoading(false); // Re-enable the button once response is received
    }
  };
  

  
  const handleForgotPwOpenDialog = () => {
    setForgotPwDialogOpen(true);
  };
  const handleForgotPwCloseDialog = () => {
    setForgotPwDialogOpen(false);
  };

   // Callback to receive OTP and email from ForgotPwPopup
   const handleOTPReceived = (generatedOtp, userEmail) => {
    setOtp(generatedOtp); // Set the OTP in state
    setEmail(userEmail);   // Set the email in state
    setForgotPwDialogOpen(false); // Close ForgotPwPopup
    setOTPModalOpen(true); // Open OTPModal
  };

  const handleCloseOTPModal = () => {
    setOTPModalOpen(false);
  };

  return (
    <div className="Login-container">

      <div className="Login-image-slider">
        {/* {images.map((image, index) => (
          <img
            key={index}
            src={image}
            alt={`Slide ${index + 1}`}
            className={`Login-slide ${currentImageIndex === index ? 'show' : ''}`}
          />
        ))}
        <div className="overlay"></div> */}
        <div className='LoginLeftContainer'>
        <img
            src={logo} alt="Avatar" 
            className='logoImage'
          />
          <h1 className="has-text-centered logoText">Smart Path</h1>        

    </div>
      </div>
      
      <div className="Login-form">
      
        {isRegistering ? (
          <Register onSwitchToLogin={switchToLogin} />
        ) : (
          <div className="EMS_Add_Event_Box box">
            <h1 style={{fontSize:'5vh'}} className="has-text-centered">Login</h1>        
            <form onSubmit={handleSubmit}>
              <div className="field">
                <FormField
                  label="Email or Username"
                  type="text"
                  name="UserName"
                  value={loginFormData.UserName}
                  placeholder="e.g. Mughal@gmail.com"
                  onChange={handleChange}
                  required={true}
                />
              </div>
              <div className="field">
                <FormField
                  label="Password"
                  type="password"
                  name="password"
                  value={loginFormData.password}
                  placeholder="********"
                  onChange={handleChange}
                  required={true}
                />
              </div>
              <div className="field" style={{ padding: '0 3%' }}>

              {/*   <button type="submit" className="button btn-bg">Login</button> */}
              <button
                type="submit"
                className="button btn-bg"
                disabled={loading} 
              >
                {loading ? "Logging In..." : "Login"} 
              </button>
              </div>
              <div className="field">
                <p className="has-text-centered">
                  Don't have an account? <button type="button" onClick={switchToRegister} className="link-button">Register here</button>
                </p>
              </div>
              <div className="field">
                <p className="has-text-centered">
                   <button type="button" onClick={handleForgotPwOpenDialog} className="link-button">Forgot Password</button>
                </p>
              </div>
            </form>   
      
            {/* Forgot Password Modal */}
            {isForgotPwDialogOpen && (
              <ForgotPwPopup
                isOpen={isForgotPwDialogOpen}
                onClose={handleForgotPwCloseDialog}
                onOTPGenerated={handleOTPReceived}  
              />
            )}

            {/* OTP Modal */}
            {isOTPModalOpen && (
              <OTPModal isOpen2={isOTPModalOpen} onClose2={handleCloseOTPModal} SendedOtp={otp} _email_={email} />
            )}

          </div>
        )}
      
      </div>
    </div>
  );
};

export default Login;
