// Function to filter decor items
const filterCheckedDecorItems = (details) => {
    const { decorItems, ...rest } = details;
    const filteredDecorItems = Object.fromEntries(
      Object.entries(decorItems).filter(([key, value]) => value.checked)
    );
    return {
      ...rest,
      decorItems: filteredDecorItems,
    };
  };
  
  // Function to filter food details (drinks and tea)
  const filterCheckedFoodDetails = (foodDetails) => {
    const { drinks, Tea, ...rest } = foodDetails;
  
    // Filter checked drinks
    const filteredDrinks = Object.fromEntries(
      Object.entries(drinks).filter(([key, value]) => value.checked)
    );
  
    // Filter checked Tea
    const filteredTea = Object.fromEntries(
      Object.entries(Tea).filter(([key, value]) => value.checked)
    );
  
    return {
      ...rest,
      drinks: filteredDrinks,
      Tea: filteredTea,
    };
  };
  
  // Function to filter payments details
  const filterPaymentsDetails = (paymentsDetails) => {
    // Filter out payments with no amount
    const filteredPayments = Object.fromEntries(
      Object.entries(paymentsDetails).filter(([key, payment]) => payment.amount > 0)
    );
  
    return filteredPayments;
  };
  
  // module.exports = {
  //   filterCheckedDecorItems,
  //   filterCheckedFoodDetails,
  //   filterPaymentsDetails,
  // };
  export { filterCheckedDecorItems, filterCheckedFoodDetails, filterPaymentsDetails };
