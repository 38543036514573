import React, { useState } from 'react';
// import '../../Styles/Register.css';
import FormField from '../../components/shared/Forms/FormField';
import {  handleRegister } from "../../services/authService";
import Alert from '@mui/material/Alert';
import {  toast } from 'react-toastify';

const Register = ({ onSwitchToLogin }) => {

  const [loading, setLoading] = useState(false);
  const [registerFormData, setRegisterFormData] = useState({
    BusinessName: '',
    UserName: '',
    location:'',
    email: '',
    phone: '',
    password: '',
    confirmPassword: '',
  });

  
  const [passwordValid, setPasswordValid] = useState({
    minLength: false,
    hasNumber: false,
    hasAlphabet: false,
    passwordsMatch: false,
  });

  const handleChange = (e) => {
    const { name, value } = e.target;

    if (name === 'password' || name === 'confirmPassword') {
      const password = name === 'password' ? value : registerFormData.password;
      const confirmPassword = name === 'confirmPassword' ? value : registerFormData.confirmPassword;

      setPasswordValid({
        minLength: password.length >= 8,
        hasNumber: /\d/.test(password),
        hasAlphabet: /[a-zA-Z]/.test(password),
        passwordsMatch: password && confirmPassword && password === confirmPassword,
      });
    }

    setRegisterFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
  
    const { minLength, hasNumber, hasAlphabet, passwordsMatch } = passwordValid;
  
    // Validate password requirements
    if (!minLength || !hasNumber || !hasAlphabet || !passwordsMatch) {
      toast.error('Please ensure your password meets the requirements and the passwords match.', {
        position: "top-right",
        hideProgressBar: true,
        autoClose: 3000,
        className: 'custom-toast',
        bodyClassName: 'custom-toast-body',
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      setLoading(false); 
      return;
    }
  
    try { 
      await handleRegister(e, registerFormData); 
    } catch (error) { 
      console.log("ERROR:",error);
      toast.error('Registration failed. Please try again.', {
        position: "top-right",
        hideProgressBar: true,
        autoClose: 3000,
        className: 'custom-toast',
        bodyClassName: 'custom-toast-body',
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } 
    finally { 
      setLoading(false);
    }
  };
  

  return (
    <>
      <form className="EMS_Add_Event_Box box" style={{marginTop:'auto'}} onSubmit={handleSubmit}>
       <h1 style={{fontSize:'5vh'}} className="has-text-centered">Register</h1>
            {/* {error && <Alert severity="error" >{error}</Alert>} */}
        <div className="field">
          <FormField
            label="Business Name"
            type="text"
            name="BusinessName"
            value={registerFormData.BusinessName}
            placeholder="Enter Hall/Business Name"
            onChange={handleChange}
            required={true}
          />
        </div>
        <div className="field">
          <FormField
            label="Username"
            type="text"
            name="UserName"
            value={registerFormData.UserName}
            placeholder="Enter Username"
            onChange={handleChange}
            required={true}
          />
        </div>
        <div className="field">
          <FormField
            label="Email"
            type="email"
            name="email"
            value={registerFormData.email}
            placeholder="e.g Ali@gmail.com"
            onChange={handleChange}
            required={true}
          />
        </div>
        <div className="field">
          <FormField
            label="Location"
            type="text"
            name="location"
            value={registerFormData.location}
            placeholder="e.g 403-B Garden Town,Lahore"
            onChange={handleChange}
            required={true}
          />
        </div>
        <div className="field">
          <FormField
            label="Phone"
            type="text"
            name="phone"
            value={registerFormData.phone}
            placeholder="e.g 0321-1234567"
            onChange={handleChange}
            required={true}
          />
        </div>
        <div className="field">
          <FormField
            label="Password"
            type="password"
            name="password"
            value={registerFormData.password}
            placeholder="********"
            onChange={handleChange}
            required={true}
          />
          <div className="password-validation">
            <p style={{ color: passwordValid.minLength ? 'green' : 'red' }}>
              {passwordValid.minLength ? '✔' : '✘'} Minimum 8 characters
            </p>
            <p style={{ color: passwordValid.hasNumber ? 'green' : 'red' }}>
              {passwordValid.hasNumber ? '✔' : '✘'} Includes a number
            </p>
            <p style={{ color: passwordValid.hasAlphabet ? 'green' : 'red' }}>
              {passwordValid.hasAlphabet ? '✔' : '✘'} Includes an alphabet
            </p>
            <p style={{ color: passwordValid.passwordsMatch ? 'green' : 'red' }}>
              {passwordValid.passwordsMatch ? '✔' : '✘'} Password match with confirm password
            </p>
          </div>
        </div>
        <div className="field">
          <FormField
            label="Confirm Password"
            type="password"
            name="confirmPassword"
            value={registerFormData.confirmPassword}
            placeholder="********"
            onChange={handleChange}
            required={true}
          />
        </div>
        <div className="field" style={{ padding: '0 3%' }}>
          <button type="submit" 
                className="button btn-bg"
                disabled={loading}>
                  {/* Register */}
                  {loading ? "Please Wait..." : "Register"} 
          </button>

        </div>
        <div className="field">
          <p className="has-text-centered">
            Already have an account? <button type="button" onClick={onSwitchToLogin} className="link-button">Login here</button>
          </p>
        </div>
      </form>
    </>
    
  );
};

export default Register;
