import React, { useState,useEffect} from 'react';
import 'bulma/css/bulma.css';
import '../../../Styles/OwnerDetailForm.css';
import FormField from './FormField';
import '../../../Styles/FoodDetailForm.css';


const FoodDetailForm = ({onNext,onEdit, onSave, onClear, formData: initialFormData, mode }) => {
  const drinkOptions = [
    { label: 'Pepsi/Coke', name: 'drinks.pepsiCoke', placeholder: 'No. of bottles/PET' },
    { label: 'Sprite/7up', name: 'drinks.sprite7up', placeholder: 'No. of bottles/PET' },
    { label: 'Fanta', name: 'drinks.fanta', placeholder: 'No. of bottles/PET' },
    { label: 'Tea', name: 'Tea.tea', placeholder: 'Quantity' },
    { label: 'Green Tea', name: 'Tea.greenTea', placeholder: 'Quantity' }
  ];

  const [formData, setFormData] = useState({
    numDishes: '',
    customNumDishes: '',
    dishes: [],
    drinks: {
      pepsiCoke: { checked: false, quantity: '' },
      sprite7up: { checked: false, quantity: '' },
      fanta: { checked: false, quantity: '' },
    },
    Tea:{
      tea: { checked: false, quantity: '' },
      greenTea: { checked: false, quantity: '' }  
    },
    Description:'',
  });
  
  useEffect(() => {
    if (initialFormData) {
      const defaultFoodDetails = {
        numDishes: '',
        customNumDishes: '',
        dishes: [],
        drinks: {
          pepsiCoke: { checked: false, quantity: '' },
          sprite7up: { checked: false, quantity: '' },
          fanta: { checked: false, quantity: '' },
        },
        Tea: {
          tea: { checked: false, quantity: '' },
          greenTea: { checked: false, quantity: '' },
        },
        ...initialFormData.foodDetails, // Merge with initial foodDetails data to override defaults
      };
  
      // console.log('defaultFoodDetails:',defaultFoodDetails);
      // console.log('initialFormData:',initialFormData);
      // Update formData state with the correct initial values
      setFormData((prevFormData) => ({
        ...prevFormData,
        ...initialFormData,
        foodDetails: defaultFoodDetails,
      }));
    }
  }, [initialFormData]);

  
  

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
    // onEdit();
    if (onEdit) onEdit();
  };

  const handleCustomNumDishesChange = (e) => {
    const { value } = e.target;
    setFormData({
      ...formData,
      numDishes: 'custom',
      customNumDishes: value,
      dishes: Array.from({ length: value }, () => ({ name: '', DishType: '' }))
    });
    // onEdit();
    if (onEdit) onEdit();
  };

  const handleDishChange = (index, e) => {
    const { name, value } = e.target;
    const newDishes = formData.dishes.slice();
    newDishes[index][name] = value;
    setFormData({
      ...formData,
      dishes: newDishes
    });
    // onEdit();
    if (onEdit) onEdit();
  };
  const handleClear = () => {
    setFormData({
      numDishes: '',
      customNumDishes: '',
      dishes: [],
      drinks: {
        pepsiCoke: { checked: false, quantity: '' },
        sprite7up: { checked: false, quantity: '' },
        fanta: { checked: false, quantity: '' },
      },
      Tea: {
        tea: { checked: false, quantity: '' },
        greenTea: { checked: false, quantity: '' }
      },
      Description:'',

    });
    onClear();

  };
  
  
  const handleCheckboxChange = (e) => {
    const { name, checked } = e.target;
    const [category, type] = name.split('.');
    
    // console.log("category, type:", category, type);
    
    setFormData((prevFormData) => ({
      ...prevFormData,
      [category]: {
        ...prevFormData[category],
        [type]: {
          ...prevFormData[category][type],
          checked: checked,
        },
      },
    }));
    
    onEdit(); // Call onEdit after state updates
    if (onEdit) onEdit();

  };
  
  const handleQuantityChange = (e) => {
    const { name, value } = e.target;
    const [category, type] = name.split('.');
    
    setFormData((prevFormData) => ({
      ...prevFormData,
      [category]: {
        ...prevFormData[category],
        [type]: {
          ...prevFormData[category][type],
          quantity: value,
        },
      },
    }));
    
    // onEdit(); // Call onEdit after state updates
    if (onEdit) onEdit();

  };

  const [isSubmitting, setIsSubmitting] = useState(false);
  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    try {
      if (mode === 'add') {
        onNext(formData); 
      } else {
        await onSave(formData);
      }
    } catch (error) {
      console.log('Error:', error);
    } finally {
      setIsSubmitting(false); // Re-enable the button after submission
    }
  };

  const dishOptions = [1, 2, 3, 'custom'];

  return (
    <div className="container" style={{ marginTop: "8%" }}>
      <div className="EMS_Add_Event_Box box">
        <h1 className="title">Food Details</h1>
        <form onSubmit={handleSubmit}>
          <div className="columns">
            <div className="field column">
              <label className="EMS_Add_Event_Label">Number of Dishes </label>
              <div className="control FoodDetailCustomDropdown">
                <div className="select is-fullwidth">
                  <select
                    name="numDishes"
                    value={formData.numDishes}
                    style={{backgroundColor:"white",color:'black'}}
                    onChange={(e) => {
                      const value = e.target.value;
                      if (value === 'custom') {
                        setFormData({ ...formData, numDishes: value, customNumDishes: '', dishes: [] });
                        onEdit();
                      } else {
                        setFormData({
                          ...formData,
                          numDishes: value,
                          customNumDishes: '',
                          dishes: Array.from({ length: value }, () => ({ name: '', DishType: '' }))
                        });
                        onEdit();
                      }
                    }}
                    // required
                  >
                    <option value="">Select number of dishes</option>
                    {dishOptions.map((option) => (
                      <option key={option} value={option}>{option}</option>
                    ))}
                  </select>
                </div>
                {formData.numDishes === 'custom' && (
                  <input
                    className="input customInputFoodDetails mx-3"
                    type="text"
                    name="customNumDishes"
                    value={formData.customNumDishes}
                    onChange={handleCustomNumDishesChange}
                    min="1"
                    maxLength='2'
                    placeholder='No. of Dishes'
                    // required
                  />
                )}
              </div>
            </div>
          </div>

          {formData.dishes.map((dish, index) => (
            <div className="columns" key={index}>
              <FormField
                label={`Dish ${index + 1} Name`}
                type="text"
                name="name"
                value={dish.name}
                onChange={(e) => handleDishChange(index, e)}
                // required={true}
              />
              <div className="field column">
                <label className="EMS_Add_Event_Label">Dish Type 
                </label>
                <div className="control">
                  <div className="select is-fullwidth">
                    <select
                      name="DishType"
                      value={dish.DishType}
                      onChange={(e) => handleDishChange(index, e)}
                      style={{backgroundColor:"white",color:'black'}}
                      // required
                    >
                      <option value="">Select type</option>
                      <option value="starter">Starter</option>
                      <option value="main course">Main Course</option>
                      <option value="sweet dish">Sweet Dish</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
          ))}


          <div className="columns DrinksSection">
            {drinkOptions.map((drink) => {
              const drinkCategory = formData[drink.name.split('.')[0]] || {};  // Check for the first level
              const drinkItem = drinkCategory[drink.name.split('.')[1]] || {}; // Check for the second level

              return (
                <div key={drink.name} className="column p-0" style={{ display: 'flex', alignItems: 'center', marginBottom: '1vh' }}>
                  <label style={{ width: '15%' }}>
                    <input
                      type="checkbox"
                      name={drink.name}
                      checked={!!drinkItem.checked} // Ensure a boolean value
                      onChange={handleCheckboxChange}
                    />
                    {drink.label}
                  </label>
                  {drinkItem.checked && (
                    <input
                      type="text"
                      name={drink.name}
                      value={drinkItem.quantity || ''}  // Default to empty string if undefined
                      onChange={handleQuantityChange}
                      placeholder={drink.placeholder}
                      className="input DrinkOptions mx-3"
                      // required
                    />
                  )}
                </div>
              );
            })}
          </div>

          <div className="field">
            <FormField
              label="Any other thing"
              name="Description"
              value={formData.Description}
              onChange={handleChange}
              placeholder="Any other thing you can add here"
              isTextarea={true}
            />
          </div>

          <div className="field is-grouped">
            <div className="control">
              <button type="button" className="button is-light" onClick={handleClear}>Clear Fields</button>
            </div>
            <div className="control">
              {/* <button type="submit" className="button btn-bg">{mode === 'add' ? 'Next' : 'Save'}</button> */}
              <button
                type="submit"
                className="button btn-bg"
                disabled={isSubmitting}
              >
                {isSubmitting ? 'Saving...' : (mode === 'add' ? 'Next' : 'Save')}
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default FoodDetailForm;
