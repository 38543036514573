import React, { useState,useEffect } from 'react';
import FormField from '../../components/shared/Forms/FormField';
import{ClearLocalStorageAtMidnight} from '../../Utils/MidnightClear'
import { useSelector } from "react-redux";
import {  toast } from 'react-toastify';
import { ToastConfig,SuccessToastConfig } from '../../components/shared/Forms/ToastConfig'; 
import API from "../../services/API";

const ChangePassword = () => {
  const { user } = useSelector((state) => state.auth);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [formData, setFormData] = useState({
    // currentPassword: '',
    newPassword: '',
    confirmPassword: ''
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true); 

    if (formData.newPassword !== formData.confirmPassword) 
      {
        toast.error('New password and confirm password must be same', ToastConfig);
        setIsSubmitting(false); 
        return;
      }
  try {
    const { data } = await API.put(`/update/changePassword/${user?.UserName}`, formData);
    if (data?.success) {
      toast.success('Password Updated Successfully!',SuccessToastConfig);
    } else {
      toast.error(data?.message || 'Error in Updating Password', ToastConfig);
    }
  } catch (error) {
    toast.error('Failed to update password. Please try again.', ToastConfig);
    // console.error('API Error:', error);
  }
  finally {
    setIsSubmitting(false); // Re-enable the button once response is received
  }
};


  useEffect(() => {
    ClearLocalStorageAtMidnight();
  },[]);

  return (
    <div className="EMS_Add_Event_Box box" style={{width:"30vw"}} >
      <h1 className="title">Change Password</h1>
      <form onSubmit={handleSubmit}>
          {/* <FormField
              label="Current Password"
              type="password"
              name="currentPassword"
              value={formData.currentPassword}
              onChange={handleChange}
              placeholder="Enter your current password"
              required={true}
            /> */}

          <FormField
              label="New Password"
              type="password"
              name="newPassword"
              value={formData.newPassword}
              onChange={handleChange}
              placeholder="Enter your new password"
              required={true}
            />

          <FormField
              label="Confirm New Password"
              type="password"
              name="confirmPassword"
              value={formData.confirmPassword}
              onChange={handleChange}
              placeholder="Confirm your new password"
              required={true}
            />

        <div className="field">
          <div className="control">
            {/* <button className="button btn-bg mx-3" type="submit">
              Save
            </button> */}
               <button
              className="button btn-bg mx-3"
              type="submit"
              disabled={isSubmitting}
            >
              {isSubmitting ? "Saving..." : "Save"}
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default ChangePassword;
