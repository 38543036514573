import React, { useState,useEffect } from 'react';
import 'bulma/css/bulma.css';
import '../../../Styles/OwnerDetailForm.css';
import FormField from './FormField';
import { format } from 'date-fns';
import { enUS } from 'date-fns/locale';
import {  toast } from 'react-toastify';
import { ToastConfig } from '../../shared/Forms/ToastConfig';

const PaymentsDetail = ({ onNext, onSave, formData: initialFormData, mode,totalCharges }) => {
  const [installments, setInstallments] = useState({
    first: { amount: '', dateReceived: format(new Date(), 'yyyy-MM-dd', { locale: enUS }), receivedBy: '' },
    second: { amount: '', dateReceived: '', receivedBy: '' },
    third: { amount: '', dateReceived: '', receivedBy: '' },
    fourth: { amount: '', dateReceived: '', receivedBy: '' },
    fifth: { amount: '', dateReceived: '', receivedBy: '' },
  });

  const [isDisabled, setIsDisabled] = useState(false);

  const [showInstallments, setShowInstallments] = useState({
    second: false,
    third: false,
    fourth: false,
    fifth: false
  });

  useEffect(() => {
    if (initialFormData) {
      // Format the dateReceived for each installment
      const formattedInstallments = { ...initialFormData };
      
      Object.keys(formattedInstallments).forEach(key => {
        if (formattedInstallments[key].dateReceived) {
          formattedInstallments[key].dateReceived = new Date(formattedInstallments[key].dateReceived)
            .toISOString()
            .split('T')[0];  // Format as YYYY-MM-DD
        }
      });
  
      setInstallments(formattedInstallments);
  
      // Automatically check the boxes if there's data in the corresponding installment
      const newShowInstallments = { ...showInstallments };
      Object.keys(formattedInstallments).forEach(key => {
        if (key !== 'first' && formattedInstallments[key].amount) {
          newShowInstallments[key] = true;
        }
      });
      setShowInstallments(newShowInstallments);
    }
  }, [initialFormData]);
  
  const handleChange = (e, installment, field) => {
    const { value, type } = e.target;
  
    // Check for negative values in number inputs
    if (type === 'number' && value < 0) {
      toast.error("Amount cannot be negative", ToastConfig);
      return;
    }
  
    // Update installments state
    setInstallments((prev) => ({
      ...prev,
      [installment]: {
        ...prev[installment],
        [field]: value
      }
    }));
  };
  
  const handleCheckboxChange = (installment) => {
    setShowInstallments((prev) => ({
      ...prev,
      [installment]: !prev[installment],
    }));
  
    setInstallments((prevInstallments) => ({
      ...prevInstallments,
      [installment]: !showInstallments[installment]
        ? { amount: '', dateReceived: '', receivedBy: '' }  // Reset if unchecked
        : prevInstallments[installment] || { amount: '', dateReceived: '', receivedBy: '' } // Initialize if checked
    }));
  };
  const isPreviousChecked = (installment) => {
    switch (installment) {
      case 'second':
        //return true; // First payment always enabled
        return installments.first.amount === ''? false:true;
      case 'third':
        return showInstallments.second;
      case 'fourth':
        return showInstallments.third;
      case 'fifth':
        return showInstallments.fourth;
      default:
        return false;
    }
  };
 
  const handleClear = () => {
    setInstallments({
      first: { amount: '', dateReceived: format(new Date(), 'yyyy-MM-dd', { locale: enUS }), receivedBy: '' },
      second: { amount: '', dateReceived: '', receivedBy: '' },
      third: { amount: '', dateReceived: '', receivedBy: '' },
      fourth: { amount: '', dateReceived: '', receivedBy: '' },
      fifth: { amount: '', dateReceived: '', receivedBy: '' },
    });
  
    setShowInstallments({
      second: false,
      third: false,
      fourth: false,
      fifth: false
    });
    // onClear();
  };
 
  const [isSubmitting, setIsSubmitting] = useState(false);
  const handleSubmit =async (e) => {
    e.preventDefault();

    // Create a filtered version of installments
    const filteredInstallments = Object.keys(installments).reduce((result, key) => {
      // Include only installments that are either 'first' or have their checkbox checked
      if (key === 'first' || showInstallments[key]) {
        // Ensure we don't accidentally include undefined values
        result[key] = installments[key] || {
          amount: '',
          dateReceived: '',
          receivedBy: ''
        };
      }
      return result;
    }, {});

    // Validate filteredInstallments
    const isValid = Object.keys(filteredInstallments).every((key) => {
      const { amount, dateReceived } = filteredInstallments[key];

      // Ensure 'amount' and 'dateReceived' are provided only for checked installments (2-5)
      if (key !== 'first' && showInstallments[key]) {
        return !!amount && !!dateReceived; // Both fields are required if checkbox is checked
      }

      // Allow empty fields for 'first' installment
      return true;
    });
  

    // if (isValid) {
    //   if (mode === 'add') {
    //     onNext(filteredInstallments);
        
    //     // Disable the button
    //     setIsDisabled(true);

    //     // Re-enable the button after 2 seconds
    //     setTimeout(() => {
    //       setIsDisabled(false);
    //     }, 2000);

    //   } else {
    //     // console.log('installments:', filteredInstallments);
    //     onSave(filteredInstallments);
    //   }
    // } else {
    //   console.log('Please fill all required fields');
    // }

    if (isValid) {
      setIsSubmitting(true); // Disable the button before submission
      try {
        if (mode === 'add') {
          onNext(filteredInstallments); 
          setIsDisabled(true);

          // Re-enable the button after 2 seconds
          setTimeout(() => {
            setIsDisabled(false);
          }, 2000);

        } else {
          await onSave(filteredInstallments);
        }
      } catch (error) {
        console.error('Submission error:', error);
      } finally {
        setIsSubmitting(false); // Re-enable button after submission
      }
    } else {
      console.log('Please fill all required fields');
    }

  };


  return (
    <div className="container" style={{marginTop:"8%"}}>
      <div className="EMS_Add_Event_Box box">
        <h1 className="title">Payment Installments</h1>
            <h3 className="title is-4" style={{ marginBottom: '1rem' }}>
              Total Charges: {totalCharges.toLocaleString()}
            </h3>
        <hr />
        <form onSubmit={handleSubmit}>
          <div className="field">
            <FormField
              label="Adv/First Payment"
              name="amount"
              type="number"
              value={installments.first.amount}
              onChange={(e) => handleChange(e, 'first', 'amount')}
              placeholder="Enter amount"
              required={false}
            />
            <FormField
              label="Receiving Date"
              name="dateReceived"
              value={installments.first.dateReceived}
              onChange={(e) => handleChange(e, 'first', 'dateReceived')}
              placeholder="YYYY-MM-DD"
              type="date"
              required={false}
            />
            <FormField
              label="Received By"
              name="receivedBy"
              type="text"
              value={installments.first.receivedBy}
              onChange={(e) => handleChange(e, 'first', 'receivedBy')}
              placeholder="Received by"
              required={false}
            />
          </div>

          {/* Payments 2-5 */}
          {['second', 'third', 'fourth', 'fifth'].map((installment, index) => (
            <div key={installment} className="field">
              <label className="checkbox">
                <input
                  type="checkbox"
                  checked={showInstallments[installment]}
                  onChange={() => handleCheckboxChange(installment)}
                  disabled={!isPreviousChecked(installment)}
                />
                {` Payment ${index + 2}`}
              </label>
              {showInstallments[installment] && (
                <>
                  <FormField
                    label={`Installment no. ${index + 2}`}
                    name="amount"
                    type="number"
                    value={installments[installment].amount}
                    onChange={(e) => handleChange(e, installment, 'amount')}
                    placeholder="Enter amount"
                    required={showInstallments[installment]}
                  />
                  <FormField
                    label={`Receiving Date`}
                    name="dateReceived"
                    value={installments[installment].dateReceived}
                    onChange={(e) => handleChange(e, installment, 'dateReceived')}
                    placeholder="YYYY-MM-DD"
                    type="date"
                    required={showInstallments[installment]}
                  />
                  <FormField
                    label={`Received By`}
                    name="receivedBy"
                    type="text"
                    value={installments[installment].receivedBy}
                    onChange={(e) => handleChange(e, installment, 'receivedBy')}
                    placeholder="Received by"
                    required={false}
                  />
                </>
              )}
            </div>
          ))}

         <div className="field is-grouped">
            <div className="control">
              <button type="button" className="button is-light" onClick={handleClear}>Clear Fields</button>
            </div>
            <div className="control">
              {/* <button type="submit" 
              disabled={isDisabled}
              className="button btn-bg"
              >
                {mode === 'add' ? 'Save Details' : 'Save'}
              </button> */}
                    <button
                      type="submit"
                      disabled={isSubmitting || isDisabled} // Combine conditions
                      className="button btn-bg"
                    >
                      {isSubmitting ? 'Saving...' : mode === 'add' ? 'Save Details' : 'Save'}
                    </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default PaymentsDetail;
